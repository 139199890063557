

import { useSelector } from 'react-redux';
import Header from '../../components/Header';
import WhiteHeader from '../../components/WhiteHeader';
import { useCreateContactUsMutation, useGetContactUsByIdQuery } from '../../store/services/contactusService';
import { useState } from 'react';
import { useCreateContactMutation } from '../../store/services/contactService';

function Contactus() {
	const { contactusid } = useSelector((state) => state.authReducer);
	const { data:contactusdata, isFetching:contactusfetch } = useGetContactUsByIdQuery(contactusid);



  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");

  const [createContact, { isLoading }] = useCreateContactMutation(); // Use the createContact mutation from the contact service

  const handleName = (e) => {
    setClientName(e.target.value);
    setErrClientName("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName("Enter your Name");
    }
    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (!messages) {
      setErrMessages("Enter your Messages");
    }
    if (clientName && email && EmailValidation(email) && messages) {
      try {
        const response = await createContact({ name: clientName, email, message:messages });
        if (response.error) {
          // Handle error response
          console.error("Error:", response.error);
        } else {
          // Clear form fields and set success message
          setSuccessMsg(
            `Thank you dear ${clientName}, Your messages has been received successfully. Further details will be sent to you by your email at ${email}.`
          );
          setClientName("");
          setEmail("");
          setMessages("");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    
<div>



<button class="scroll__top scroll-to-target" data-target="html">
    <i class="fas fa-angle-up"></i>
</button>

<main class="fix">


    <section class="breadcrumb__area fix">
        <div class="container">
            <div class="row align-items-end">
                <div class="col-lg-8">
                    <div class="breadcrumb__content">
                        <h3 class="title">Contact Page</h3>
                        <nav class="breadcrumb">
                            <span property="itemListElement" typeof="ListItem">
                                <a href="/">Home</a>
                            </span>
                            <span class="breadcrumb-separator"><i class="flaticon-right-arrow-angle"></i></span>
                            <span property="itemListElement" typeof="ListItem">Contact</span>
                        </nav>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="breadcrumb__img">
                        <img src="assets/img/images/breadcrumb_img.png" alt="img" data-aos="fade-left" data-aos-delay="800"/>
                    </div>
                </div>
            </div>
        </div>
       
    </section>
    
    <section class="contact__area">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="contact__content">
                        <div class="section__title mb-30">
                            <h2 class="title">We Are Always Available For You & Your Pets</h2>
                            <p>Get in Touch with us Now! .</p>
                        </div>
                        <div class="contact__info-wrap">
                            <h6 class="title">Information:</h6>
                            <ul class="list-wrap">
                                <li>
                                    <div class="icon">
                                        <i class="flaticon-phone"></i>
                                    </div>
                                    <a href={`tel:${contactusdata?.phoneNumber}`}>{contactusdata?.phoneNumber}</a>
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class="flaticon-placeholder"></i>
                                    </div>
                                    {contactusdata?.address}
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class="flaticon-mail"></i>
                                    </div>
                                    <a href={`mailto:${contactusdata?.emailAddress}`}>{contactusdata?.emailAddress}</a>
                                </li>
                                <li>
                                    <div class="icon">
                                        <i class="fas fa-share-alt"></i>
                                    </div>
                                    <ul class="list-wrap contact__social">
                                        <li><a href={contactusdata?.facebookPageLink} target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href={contactusdata?.twitterPageLink} target="_blank"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href={`https://api.whatsapp.com/send?phone=${contactusdata?.whatsappNumber}&text=${contactusdata?.whatsappMessage}`} target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                                        <li><a href={contactusdata?.instagramPageLink} target="_blank"><i class="fab fa-instagram"></i></a></li>
                                        <li><a href={contactusdata?.youtubePageLink} target="_blank"><i class="fab fa-youtube"></i></a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="contact__form-wrap">
                    {successMsg ? (
        <p className="pb-20 w-96 font-medium text-green-500">{successMsg}</p>
      ) : (   <form action="assets/mail.php" method="POST" id="contact-form" class="contact__form">
                            <h2 class="title">Post a comment</h2>
                            <span>Your email address will not be published. Required fields are marked *</span>
                            <div class="row gutter-20">
                                <div class="col-md-6">
                                    <div class="form-grp">
                                    <input
                onChange={handleName}
                value={clientName}
                // className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Enter your name here"
              />
              {errClientName && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errClientName}
                </p>
              )}
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-grp">
                                    <input
                onChange={handleEmail}
                value={email}
                type="email"
                placeholder="Enter your email here"
              />
              {errEmail && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
                                    </div>
                                </div>
                                
                                <div class="col-md-12">
                                    <div class="form-grp">
                                    <textarea
                onChange={handleMessages}
                value={messages}
                cols="30"
                rows="3"
                placeholder="Enter your message here"
              ></textarea>
              {errMessages && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errMessages}
                </p>
              )}
                                    </div>
                                </div>
                            </div>
                            <button type="button"
                             onClick={handlePost}
                             disabled={isLoading}
                            class="btn"> {isLoading ? "Sending..." : "Send Message"} <img src="assets/img/icon/right_arrow.svg" alt="" class="injectable"/></button>
                        </form>)}
                        <p class="ajax-response mb-0"></p>
                    </div>
                </div>
            </div>
            <div class="contact-map">
                <iframe src={contactusdata?.googleMapLocationEmbedLink} style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </section>

</main>

</div>
    

  );
}

export default Contactus;
