import React, { useState, useEffect } from 'react'
import { FaTimes, FaGooglePlay, FaAppStore, FaPaw, FaHeart } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const Homepopup = ({setshow_appModule}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isAnimating, setIsAnimating] = useState(false)
  const navigate = useNavigate();
  useEffect(() => {
    // Show popup after 1.5 seconds of page load for better UX
    const timer = setTimeout(() => {
      setIsOpen(true)
    }, 1500)

    return () => clearTimeout(timer)
  }, [])

  const handleClose = () => {
    setIsAnimating(true)
    setshow_appModule(false)
    setTimeout(() => {
      setIsOpen(false)
      setIsAnimating(false)
    }, 300)
  }

  if (!isOpen) return null

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-black/70 backdrop-blur-md z-[9999] p-4 transition-opacity duration-300 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}>
      <div className={`bg-gradient-to-br from-white to-gray-50 rounded-3xl shadow-2xl p-6 sm:p-8 md:p-10 max-w-lg w-full mx-auto relative transition-all duration-300 ${isAnimating ? 'scale-95 opacity-0' : 'scale-100 opacity-100'}`}>
        <button 
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-red-500 transition-colors duration-200 hover:rotate-90 transform focus:outline-none focus:ring-2 focus:ring-red-400 rounded-full p-1.5 hover:bg-red-50"
          aria-label="Close popup"
        >
          <FaTimes className="w-5 h-5" />
        </button>

        <div className="text-center">
          <div className="relative mb-8">
            <div className="absolute -top-2 -left-2 w-full h-full bg-gradient-to-br from-pink-200 to-purple-200 rounded-full blur-xl opacity-50 animate-pulse"></div>
            <img 
              src="https://img.freepik.com/free-vector/cute-dog-cat-friend-cartoon_138676-2432.jpg"
              alt="Cute Pets" 
              className="w-36 h-36 md:w-44 md:h-44 mx-auto mb-6 drop-shadow-2xl hover:scale-105 transition-transform duration-300 rounded-full object-cover ring-4 ring-white"
            />
            <div className="absolute -right-2 top-1/2 transform -translate-y-1/2">
              <FaPaw className="w-8 h-8 text-pink-400 animate-bounce delay-100" />
            </div>
            <div className="absolute -left-2 top-1/4">
              <FaHeart className="w-6 h-6 text-red-400 animate-pulse" />
            </div>
            
            <h2 className="text-3xl md:text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-4 tracking-tight">Pawsome Mobile Experience!</h2>
            <p className="text-gray-600 text-base md:text-lg mb-8 leading-relaxed max-w-md mx-auto">
              Join our furry community! Get instant pet care tips, connect with other pet lovers, and access exclusive features through our mobile app. 🐾
            </p>
            
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-8">
              <button onClick={() => window.open("https://play.google.com/store/apps/details?id=com.pet.petzoy&hl=en", "_blank")}
       className="flex items-center justify-center w-full sm:w-auto px-8 py-4 bg-gradient-to-r from-purple-600 to-pink-600 text-white rounded-xl hover:shadow-xl hover:-translate-y-1 transition-all duration-300 group focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <FaGooglePlay className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
                <span className="font-semibold">Google Play</span>
              </button>
              
              <button onClick={() => window.open("https://apps.apple.com/us/app/petzoy/id6737799217", "_blank")} className="flex items-center justify-center w-full sm:w-auto px-8 py-4 bg-gradient-to-r from-blue-500 to-cyan-500 text-white rounded-xl hover:shadow-xl hover:-translate-y-1 transition-all duration-300 group focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <FaAppStore className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
                <span className="font-semibold">App Store</span>
              </button>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6 relative">
            <div className="absolute inset-x-0 -top-3">
              <div className="h-1 bg-gradient-to-r from-transparent via-pink-200 to-transparent"></div>
            </div>
            <div className="flex items-center justify-center mb-4">
              <div className="flex text-yellow-400 hover:scale-105 transition-transform duration-300">
                {[...Array(5)].map((_, i) => (
                  <span key={i} className="text-2xl animate-pulse delay-75" style={{ animationDelay: `${i * 100}ms` }}>★</span>
                ))}
              </div>
              <span className="text-gray-600 ml-3 font-medium">
                <span className="text-purple-600 font-bold">100+</span> happy pet lovers
              </span>
            </div>
            <p className="text-gray-500 text-sm md:text-base font-medium">
              Your furry friends deserve the best! Download now and get <span className="text-pink-500">exclusive treats</span> 🎁
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homepopup