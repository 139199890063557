import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useCatProductsQuery,
  useGetSubcategoryProductsQuery,
} from "../../store/services/homeProducts";
import { discount } from "../../utils/discount";
import currency from "currency-formatter";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import HomeProducts from "../../components/HomeProducts/HomeProducts";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetSubcategoriesByCategoryQuery } from "../../store/services/subcategoryService";
import MainContent from "../../components/Shop/MainContent";
import Layout from "../../components/Shop/Layout";
import Header from "../../components/Shop/Header";
import Sidebar from "./Components/Sidebar";
import Feed from "./Components/Feed";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import Joyride, { STATUS } from "react-joyride";
import { setDemoShow } from "../../store/reducers/authReducer";
import { useGetOverlayStepsByPageQuery } from "../../store/services/overlayService";
import { useGetProductsQuery } from "../../store/services/productService";
import { useGetUserByIdQuery } from "../../store/services/authService";
import { useSendPaymentMutation } from "../../store/services/paymentService";
import useRazorpay from "react-razorpay";

const DonateShop = () => {
  const dispatch = useDispatch();
  
  
  const { data, isFetching } = useGetProductsQuery({
   
  });

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");


  const { state } = useLocation();
  const { selected_member } = state;


const [selected_product, setselected_product] = useState(null)
  const navigate = useNavigate();
  
  const [doPayment, response] = useSendPaymentMutation();
  const { userToken, user } = useSelector((state) => state.authReducer);
  const [paymentMethod, setPaymentMethod] = useState("online");

  const [userData, setUserData] = useState({
    name: "",
    email: "",
  });
  const [formAddressData, setFormAddressData] = useState({
    addressLine1: "",

    postalCode: "",
    state: "",
    country: "India",
  });
  console.log(user);
  const { data: userProfile, isSuccess } = useGetUserByIdQuery(user?._id);
  const [checkoutLoading, setcheckoutLoading] = useState(false);

  const [shippingCharge, setShippingCharge] = useState(50);

  const { _id, firstName } = useSelector((state) => state.authReducer.user);



  useEffect(() => {
    if (isSuccess) {
      setUserData({
        name: userProfile.firstName,
        email: userProfile.email,
      });
    }
  }, [isSuccess, userProfile]);

  useEffect(() => {
    setFormAddressData({
      ...formAddressData,
      addressLine1: selected_member.location,

      postalCode: selected_member.location,
      state: "",
      country: "India",
    });
  }, [selected_member]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "email") {
      setUserData({
        ...userData,
        [name]: value,
      });
    } else {
      setFormAddressData({
        ...formAddressData,
        [name]: value,
      });
    }
  };
  

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCheckout = async () => {
    setcheckoutLoading(true);
    
      doPayment({ cart:[selected_product], id: user._id });
    
      console.log("Processing online payment...");
  };

  useEffect(() => {
    if (response?.isSuccess) {
      handlePayment(response?.data?.id);
    }
  }, [response]);



  const [Razorpay, isLoaded] = useRazorpay();

  const handlePayment = useCallback(async (mainId) => {
    // const order = await createOrder(params);

    const options = {
      key: "rzp_test_H93fmAWVWOOKdH",
      amount: "3000",
      currency: "INR",
      name: "PetZoy",
      description: "Test Transaction",
      image: "https://api.petzoy.in/mainlogo/logo.png",
      order_id: mainId,
      handler: (res) => {
        console.log(res);
      },
      prefill: {
        name: firstName,
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Petzoy Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);

  // useEffect(() => {
  //   if (isLoaded) {
      
  //   }
  // }, [isLoaded, handlePayment])


  return (
<div>
      <main class="fix">
        <section class="breadcrumb__area fix">
          <div class="container">
            <div class="row align-items-end">
              <div class="col-lg-8">
                <div class="breadcrumb__content">
                  <h3 class="title">Donate to {selected_member?.firstName}</h3>
                  <nav class="breadcrumb">
                    <span property="itemListElement" typeof="ListItem">
                      <a href="/">Profile</a>
                    </span>
                    <span class="breadcrumb-separator">
                      <i class="flaticon-right-arrow-angle"></i>
                    </span>
                    <span property="itemListElement" typeof="ListItem">
                      Donate
                    </span>
                  </nav>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="breadcrumb__img">
                  <img
                    src="assets/img/images/breadcrumb_img.png"
                    alt="img"
                    data-aos="fade-left"
                    data-aos-delay="800"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="">
          <div class="container">
            {!selected_product && (
             
  
    <div className="flex flex-col min-h-screen w-full mx-auto bg-white">
     

      <main className="flex flex-col xl:flex-row max-w-7xl mx-auto w-full">
        {/* Sidebar */}
      
      
<section className="product__area-two">
        <div className="container">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {data?.products.map((product, index) => {
                
              return (
                <div key={index} className="bg-white rounded-lg p-4 shadow-md">
                  <div className="product__item">
                    <div className="product__thumb relative">
                      <Link to={`/product_details/${product?._id}`}>
                        <img
                          src={`https://api.petzoy.in/assets/${product?.images[0]}` || "assets/img/products/latest_products_img01.jpg"}
                          alt={product.title}
                          className="w-full h-48 object-cover rounded-t-lg"
                        />
                      </Link>
                      <div className="absolute top-2 left-2 bg-green-500 text-white px-2 py-1 rounded-full text-xs">
                        New
                      </div>
                    </div>
                    <div className="product__content mt-4">
                     
                      <h4 className="title text-lg font-semibold mb-2">
                        <Link to={`/product_details/${product?._id}`}>
                          {product.title}
                        </Link>
                      </h4>
                      <div className="flex justify-between items-center mt-2">
                        <h3 className="price text-lg font-bold">
                          {currency.format(discount(product?.price, product?.discount), { code: "INR" })}
                          {product?.discount != 0 ? (  <del className="ml-2 text-sm text-gray-500">
                            {currency.format(product?.price, { code: "INR" })}
                          </del>):null}
                        </h3>
                        {product?.stock === 0 ? (
                          <button className="bg-gray-500 text-white px-3 py-2 rounded-lg text-sm" disabled>
                            Out of Stock
                          </button>
                        ) : (
                          <button
                            onClick={() => setselected_product(product)}
                            className="bg-purple-600 hover:bg-purple-700 text-white px-3 py-2 rounded-lg text-sm transition duration-300"
                          >
                            Donate Now
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      </main>
    </div>
            )}

            {selected_product && (
              <section className="shop-checkout container">
                <div className="row pb-10">
                  <div className="col-md-9">
                    <div className="billing-info__wrapper">
                      <h4>BILLING DETAILS</h4>
                      <div className="row">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-floating my-3">
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                value={userData.name}
                                disabled
                                placeholder="First Name"
                              />
                              <label for="checkout_first_name">
                                First Name
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-floating my-3">
                              <input
                                type="text"
                                className="form-control"
                                name="email"
                                value={userData.email}
                                disabled
                                placeholder="E-Mail"
                              />
                              <label for="checkout_last_name">E-Mail</label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="search-field my-3">
                              <div className="form-label-fixed hover-container">
                                <label
                                  for="search-dropdown"
                                  className="form-label"
                                >
                                  Country / Region*
                                </label>
                                <div className="js-hover__open">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg search-field_actor search-field_arrow-down"
                                    id="search-dropdown"
                                    name="search-keyword"
                                    disabled
                                    readonly
                                    placeholder="India"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-floating mt-3 mb-3">
                              <input
                                disabled
                                readonly
                                type="text"
                                className="form-control"
                                name="addressLine1"
                                value={formAddressData.addressLine1}
                                onChange={handleChange}
                                placeholder="Street Address *"
                              />
                              <label for="checkout_company_name">
                                Street Address *
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-floating my-3">
                              <input
                                disabled
                                readonly
                                type="text"
                                className="form-control"
                                name="postalCode"
                                value={formAddressData.postalCode}
                                onChange={handleChange}
                                placeholder="Postcode / ZIP *"
                              />
                              <label for="checkout_zipcode">Postal Code</label>
                            </div>
                          </div>

                          {/* <div className="col-md-12">
                     <div className="form-floating my-3">
                     <input disabled readonly type="text" className="form-control" name="state" value={formAddressData.state} onChange={handleChange} placeholder="State *"/>
                       <label for="checkout_email">State *</label>
                     </div>
                   </div> */}
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                   <div className="mt-3">
                     <textarea className="form-control form-control_gray" placeholder="Order Notes (optional)" cols="30"
                       rows="8"></textarea>
                   </div>
                 </div> */}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <form name="checkout-form" action>
                      <div className="shopping-cart">
                        <div className="shopping-cart__totals-wrapper">
                          <div className="sticky-content">
                            <div className="shopping-cart__totals">
                              <h3>My Donation</h3>
                              <table className="cart-totals">
                                <tbody>
                                  <tr>
                                    <th>Subtotal</th>
                                    <td style={{ textAlign: "end" }}>
                                      {" "}
                                      {currency.format(
                                         discount(
                                              selected_product?.price,
                                              selected_product?.discount
                                            ),
                                        {
                                          code: "INR",
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>Shipping Charges</th>
                                    <td style={{ textAlign: "end" }}>
                                      {" "}
                                      {currency.format(
                                         selected_product?.shipping,
                                        {
                                          code: "INR",
                                        }
                                      )}
                                    </td>
                                  </tr>

                                  {/* <tr>
                         <th>GST</th>
                         <td style={{textAlign:"end"}}>₹ 19</td>
                       </tr> */}
                                  <tr>
                                    <th>Total</th>
                                    <td style={{ textAlign: "end" }}>
                                      {" "}
                                      {currency.format(
                                       selected_product?.shipping ||
                                              0 +
                                                discount(
                                                  selected_product?.price,
                                                  selected_product?.discount
                                                ),
                                        {
                                          code: "INR",
                                        }
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th>
                                      {" "}
                                      <input
                                        type="radio"
                                        className="form-check-input form-check-input_fill"
                                        name="checkout_payment_method"
                                        id="checkout_payment_method_online"
                                        value="online"
                                        checked={paymentMethod === "online"}
                                        onChange={handlePaymentMethodChange}
                                      />
                                    </th>
                                    <td style={{ textAlign: "end" }}>
                                      Online Payment
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                              <div className="policy-text">
                                Your personal data will be used to process your
                                order, support your experience throughout this
                                website, and for other purposes described in our{" "}
                                <a href="terms.html" target="_blank">
                                  privacy policy
                                </a>
                                .
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="checkout-form">
                        <div className="checkout__totals-wrapper">
                          <div className="mobile_fixed-btn_wrapper">
                            <button
                              onClick={handleCheckout}
                              disabled={checkoutLoading}
                              className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300"
                            >
                              {checkoutLoading ? "Processing" : "Donate Order"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            )}
          </div>
        </section>

      </main>
    </div>




  );
};

export default DonateShop;
