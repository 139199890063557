
import React from 'react'
import { useLocation, useParams } from 'react-router-dom';

const DynamicPages = () => {
    const {name } = useParams();
    const { state: locationstate } = useLocation();

  const { value } = locationstate;
  return (
    <main class="fix">
        <section class="category__area" style={{height:"100vh"}}>
          <div class="container">
            <div class="row align-tiems-center">
              <div class="col-md-8">
                <div class="section__title-two mb-30">
                  <h2 class="title">
                  {name}
                    <img
                      src="assets/img/images/title_shape.svg"
                      alt=""
                      class="injectable"
                    />
                  </h2>
                </div>
              </div>
            </div>
            <div 
                        className="rich-text-content" 
                        dangerouslySetInnerHTML={{ __html: value }} 
                        style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 100px)' }} 
                    />
    </div>
    </section>
    </main>
  )
}

export default DynamicPages