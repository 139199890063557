import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import FlexBetween from "../../components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import toast, { Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useZIM } from "../../hooks/zim";
import { setLogin, setUserToken } from "../../store/reducers/authReducer";
import { useDispatch } from "react-redux";
import OTPInput from "react-otp-input";
function Register() {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [{ callID }, zimAction] = useZIM();
  const [user_coordinates, setuser_coordinates] = useState({
    latitude: "",
    longitude: "",
  });
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    occupation: "",
    password: "",
    confirmpassword: "",
    location: "",
    picture: "",
    phone: "",
  });
  const dispatch = useDispatch();
  const [showotpinput, setshowotpinput] = useState(false);
  const [otp, setotp] = useState(0);
  const [randomotp, setrandomotp] = useState(0);

  const [timer, setTimer] = useState(30); // Timer for resend button
  const [resendDisabled, setResendDisabled] = useState(false); // Disable resend button

  useEffect(() => {
    let interval;
    if (timer == 1) {
      setResendDisabled(false);
    }
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setResendDisabled(true); // Disable resend button
    setTimer(30);
setotp(0)
    // // Make a request to your backend to send OTP
    // const response = await fetch('https://api.petzoy.in/send-otp', {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ phone: formData.phone })
    // });
    // const data = await response.json();

    // // Handle the response, e.g., show OTP input field
    // console.log(data);

    if (window.sendOtp) {
      window.sendOtp(
        `91${formData.phone}`,
        (data) => console.log("OTP sent successfully.", data),
        (error) => console.log("Error occurred", error)
      );
    } else {
      console.error("sendOtp function is not available.");
    }

    // setrandomotp(data.otp);
    setshowotpinput(true);
  };

  const registeruser = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    if (window.verifyOtp) {
      console.log(JSON.stringify(window.verifyOtp));
      window.verifyOtp(
        otp,
        async (data) => {
          console.log("OTP verified: ", data);
          // Hit your register API with formData
          const apiformData = new FormData();

          Object.entries(formData).forEach(([key, value]) => {
            apiformData.append(key, value);
          });
          apiformData.append("latitude", user_coordinates.latitude);
          apiformData.append("longitude", user_coordinates.longitude);
          // apiformData.append("picturePath", formData.picture.name);

          const savedUserResponse = await fetch(
            "https://api.petzoy.in/auth/register",
            {
              method: "POST",
              body: apiformData,
            }
          );
          const savedUser = await savedUserResponse.json();
          if (savedUser?.errors?.length > 0) {
            toast.error(savedUser.errors[0].msg);
            return;
          }

          if (savedUser) {
            zimAction
              .login({
                userID: savedUser._doc._id,
                userName: savedUser._doc.name,
              })
              .then(() => {
                zimAction.initEvent();
                zimAction.updateUserInfo(
                  savedUser._doc.name,
                  `https://api.petzoy.in/assets/${savedUser._doc?.picturePath}`
                );
                // alert("user login done")
              });
            dispatch(
              setLogin({
                user: savedUser._doc,
                token: savedUser.token,
              })
            );
            console.log("dispatch hit with data", savedUser._doc);
            localStorage.setItem("userToken", savedUser?.token);

            dispatch(setUserToken(savedUser.token));

            toast.success("Welcome to Petzoy");
            setTimeout(() => {
              navigate("/editprofile");
            }, 1000);
          }
        },
        (error) => {
          console.log(error);
          toast.error("OTP Mismatch");
        }
      );
    } else {
      console.error("verifyOtp function is not available.");
      toast.error("OTP Error");
    }
  };

  const validateForm = () => {
    const { firstName, phone } = formData;
    if (!firstName || !phone) {
      console.log(formData);
      toast.error("Name and Phone is required !");
      return false;
    }
    if (phone.length < 9) {
      console.log(formData);
      toast.error("Enter Valid Phone Number !");
      return false;
    }

    if (!isChecked) {
      toast.error("Please Agree to Our Terms & Conditions");
      return false;
    }
    // if (password !== confirmpassword) {
    //     toast.error('Password and confirm password do not match');

    //   return false;
    // }
    return true;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFormData({ ...formData, picture: file });
  };

  function getLocationInfo(latitude, longitude) {
    setuser_coordinates({ latitude: latitude, longitude: longitude });
    const url = `https://api.petzoy.in/reverse-geocoding?lat=${latitude}&lon=${longitude}&format=json&language=en`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.address) {
          setFormData({ ...formData, location: data.display_name });
          // console.log("results:", data.display_name,data.address.county,data.address.suburb,data.address.state_district,data.address.state);
        } else {
          console.log("Reverse geolocation request failed.");
        }
      })
      .catch((error) => console.error(error));
  }
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos) {
    var crd = pos.coords;
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);

    getLocationInfo(crd.latitude, crd.longitude);
  }

  function errors(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <main class="fix">
        <section class="breadcrumb__area fix">
          <div class="container">
            <div class="row align-items-end">
              <div class="col-lg-8">
                <div class="breadcrumb__content">
                  <h3 class="title">Get Started With Us</h3>
                  <nav class="breadcrumb">
                    <span property="itemListElement" typeof="ListItem">
                      <a href="/">Home</a>
                    </span>
                    <span class="breadcrumb-separator">
                      <i class="flaticon-right-arrow-angle"></i>
                    </span>
                    <span property="itemListElement" typeof="ListItem">
                      Register
                    </span>
                  </nav>
                </div>
              </div>
              {/* <div class="col-lg-4">
                <div class="breadcrumb__img">
                  <img
                    src="assets/img/images/breadcrumb_img.png"
                    alt="img"
                    data-aos="fade-left"
                    data-aos-delay="800"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section class="registration__area-two">
          <div class="container">
            <div class="registration__inner-wrap-two">
              <div class="row">
                <div class="col-lg-8">
                  <div class="registration__form-wrap">
                    <form
                      onSubmit={registeruser}
                      className="registration__form"
                    >
                      <h3 className="title">Register Now</h3>
                      <span>
                        Your email address will not be published. Required
                        fields are marked *
                      </span>
                      <div className="row gutter-20">
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              disabled={showotpinput}
                              type="text"
                              placeholder="Your Name"
                              name="firstName"
                              onChange={handleChange}
                              value={formData.firstName}
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              disabled={showotpinput}
                              type="number"
                              placeholder="Phone Number"
                              name="phone"
                              onChange={handleChange}
                              value={formData.phone}
                            />
                          </div>
                        </div>

                        <label>
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                          {"  "} By Continuing You Agree to Our{" "}
                          <Link to={{ pathname: `/docs/terms&conditions` }}>
                            Terms & Conditions
                          </Link>
                        </label>
                        {/* <div className="col-md-6">
                          <div className="form-grp select-grp">
                            <select name="occupation" className="orderby" onChange={handleChange}>
                              <option value="neighbour">Neighbour</option>
                              <option value="Veterinary">Veterinary</option>
                              <option value="User">User</option>
                              <option value="Shop Owner">Shop Owner</option>
                              <option value="Tester">Tester</option>
                            </select>
                          </div>
                        </div> */}
                        {/*  <div className="col-md-6">
                          <div className="form-grp">
                            <input disabled={showotpinput} type="password" placeholder="Password" name="password" onChange={handleChange} />
                          </div>
                        </div>*/}
                        {/*   <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              type="password"
                              disabled={showotpinput}
                              placeholder="Confirm Password"
                              name="confirmpassword"
                              onChange={handleChange}
                            />
                          </div>
                        </div>*/}
                        {/* <div className="col-md-12">
                          <div className="form-grp">
                            <input type="text" placeholder="Location" name="location" onChange={handleChange}  value={formData.location}/>
                          </div>
                        </div> */}
                        {/* <div className="col-md-12">
                          <Dropzone
                            acceptedFiles=".jpg,.jpeg,.png"
                            multiple={false}
                            onDrop={handleDrop}
                            name="picture" // Add name attribute
                          >
                            {({ getRootProps, getInputProps }) => (
                              <Box
                                border={`2px dashed ${palette.primary.main}`}
                                p="1rem"
                                sx={{ "&:hover": { cursor: "pointer" } }}
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                {!formData.picture ? (
                                  <p>Add Picture Here</p>
                                ) : (
                                  <FlexBetween>
                                    <Typography>{formData.picture.name}</Typography>
                                    <EditOutlinedIcon />
                                  </FlexBetween>
                                )}
                              </Box>
                            )}
                          </Dropzone>
                        </div> */}

                        {showotpinput && (
                          <>
                            <OTPInput
                              value={otp}
                              onChange={setotp}
                              numInputs={4}
                              // renderSeparator={<span>-</span>}
                              renderInput={(props) => (
                                <input
                                  {...props}
                                  type="number"
                                  style={{
                                    width: "50px", // Adjust the width as needed
                                    height: "50px", // Adjust the height as needed
                                    fontSize: "20px", // Adjust the font size as needed
                                    textAlign: "center", // Center the text horizontally
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                />
                              )}
                            />
                            {timer > 0 && <p>Resend OTP in {timer} seconds</p>}
                            {timer === 0 && (
                              <button
                                className="btn"
                                onClick={() => handleSubmit()}
                                disabled={resendDisabled}
                              >
                                Resend OTP
                              </button>
                            )}
                          </>
                        )}
                      </div>
                      {showotpinput == false && (
                        <button
                          type="button"
                          className="btn"
                          onClick={() => handleSubmit()}
                        >
                          Continue
                          <img
                            src="assets/img/icon/right_arrow.svg"
                            alt=""
                            className="injectable"
                          />
                        </button>
                      )}

                      {showotpinput && otp.length == 4 && (
                        <button type="submit" className="btn">
                          Register Now
                          <img
                            src="assets/img/icon/right_arrow.svg"
                            alt=""
                            className="injectable"
                          />
                        </button>
                      )}
                      <p className="text-sm mt-10 font-titleFont font-medium">
                        Already a Member ?{" "}
                        <Link to="/signin">
                          <span className="hover:text-blue-600 duration-300">
                            Log In
                          </span>
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="registration__img">
                    <img
                      src="assets/img/images/registration_img.png"
                      alt=""
                      data-aos="fade-right"
                      data-aos-delay="400"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Register;
