import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const notificationService = createApi({
    reducerPath: "notificationService",
    tagTypes: "notifications",
    baseQuery: fetchBaseQuery({
        baseUrl: "https://api.petzoy.in/api/notifications/",
        prepareHeaders: (headers, { getState }) => {
            const reducers = getState();
            const token = reducers?.authReducer?.userToken;
            headers.set("authorization", token ? `Bearer ${token}` : "");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: (receiverId) => `/${receiverId}`,
        }),
        getFilteredNotifications: builder.query({
            query: ({ receiverId, types }) => `/${receiverId}/filter?types=${types}`,
        }),
        acceptOrRejectNotification: builder.mutation({
            query: ({ notificationId, action }) => ({
                url: `/action/${notificationId}`,
                method: "POST",
                body: { action },
            }),
            invalidatesTags: ["notifications"],
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useGetFilteredNotificationsQuery,
    useAcceptOrRejectNotificationMutation,
} = notificationService;

export default notificationService;
