
import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'

const InfoNotification = ({notification}) => {
  return (
    
    <div
    key={notification._id}
    className={`px-4 py-3 hover:bg-gray-50 ${
      notification.status == "unread" ? "bg-blue-50" : ""
    }`}
  >
    <div className="flex items-center">
      <div className="flex-shrink-0 mr-3">
        <div className="relative">
          <img
            src={"https://api.petzoy.in/assets/"+notification?.sender?.picturePath}
            alt="User avatar"
            className="w-12 h-12 rounded-full"
          />
          <div className={`absolute -bottom-1 -right-1 bg-green-500 rounded-full p-1`}>
          <FaCheckCircle className="text-white"/>
          </div>
        </div>
      </div>
      <div className="flex-grow">
        <p className="text-sm text-gray-900">
          <span className="font-semibold">{notification?.sender?.firstName} </span>
          <br/>
          {notification?.message}
        </p>
        <p className="text-xs text-gray-500 mt-1">
        {notification?.createdAt?.split("T")[0]} 
        </p>
       
      </div>
      {/* <div className="flex-shrink-0 ml-2">
        <button
          onClick={() => markAsRead(notification.id)}
          className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100"
        >
          <FaEllipsisH />
        </button>
      </div> */}
    </div>
  </div>
  )
}

export default InfoNotification
