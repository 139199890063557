import { useMediaQuery } from "@mui/material";
import React, { useState, Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useGetAllActiveBannerImagesForUserQuery } from "../../store/services/bannerImageService";
import { useGetReportByNameQuery } from "../../store/services/reportService";
import "./Banner.css";

const Banner = ({setshow_appModule}) => {
  const { data, isFetching } = useGetAllActiveBannerImagesForUserQuery();
  const [dotActive, setDocActive] = useState(0);
  const {
    data: speeddata,
    error,
    isLoading,
  } = useGetReportByNameQuery("slider_speed");

  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          left: "10px",
          zIndex: 10,
          color: "black", // Example: Change arrow color
          fontSize: "30px", // Example: Change arrow size
          cursor: "pointer", // Example: Add cursor pointer,
          position: "absolute",
          top: "40%",
          backgroundColor: "rgba(255,255,255,0.5)",
          padding: "10px",
          borderRadius: 50,
        }}
        onClick={onClick}
      >
        <i class="fa-solid fa-angle-left"></i>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          right: "10px",
          zIndex: 10,
          color: "black", // Example: Change arrow color
          fontSize: "30px", // Example: Change arrow size
          cursor: "pointer", // Example: Add cursor pointer
          top: "40%",
          right: "10px",
          position: "absolute",
          backgroundColor: "rgba(255,255,255,0.5)",
          padding: "10px",
          borderRadius: 50,
        }}
        onClick={onClick}
      >
        <i class="fa-solid fa-angle-right"></i>
      </div>
    );
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 1,
    dots: false,
    autoplay: true,
    speed: speeddata?.value ? Number(speeddata.value) * 100 : 400,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (prev, next) => {
      setDocActive(next);
    },

    //     // responsive: [
    //     //   {
    //     //     breakpoint: 576,
    //     //     settings: {
    //     //       dots: false,
    //     //     },
    //     //   },
    //     // ],

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const filteredData = data?.filter((item) => item.active == true); // Assuming 'status' is a boolean property

  return (
    <div className="w-full nearbybanner" style={{ background: "rgb(244,241,245)" }}>
      <Slider {...settings}>
        {!isFetching &&
          filteredData?.map((item, index) => {
            return (
              <Link onClick={() => setshow_appModule(true)}>
                <div
                  style={{
                    height: isNonMobileScreens ? "530px" : "150px",
                    overflow: "hidden",
                    background: "rgb(244,241,245)",
                    margin: isNonMobileScreens ? "0 10px" : "0 5px",
                  }}
                >
                  <div
                    className="image-container"
                    style={{ height: isNonMobileScreens ? "530px" : "150px" }}
                  >
                    <img
                      src={`https://api.petzoy.in/assets/${item?.image}`}
                      alt={item?.image}
                      style={{
                        objectFit: "fill",
                        borderRadius: "10px",
                        width: isNonMobileScreens ? "100%" : "90%", // This ensures the image takes up the full width of the container
                        height: "100%", // This ensures the image takes up the full height of the container
                      }}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
      </Slider>
    </div>
  );
};

export default Banner;
