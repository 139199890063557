import React, { useEffect, useState } from "react";
import Friend from "../../components/Friend";
import { useGetSpecificPetbyidQuery } from "../../store/services/allpetService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import WhiteHeader from "../../components/WhiteHeader";
import { useZIM } from "../../hooks/zim";
import { useGetUserByIdQuery } from "../../store/services/authService";
import MyPetListWidget from "../widgets/MyPetListWidget";
import { useSelector } from "react-redux";
import {
  useAcceptFollowMutation,
  useAcceptFriendRequestMutation,
  useCheckFollowingQuery,
  useCheckFriendRequestsQuery,
  useDeclineFriendRequestMutation,
  useDeleteFriendRequestMutation,
  useRemoveFollowerMutation,
  useSendFriendRequestMutation,
} from "../../store/services/FriendRequestService";
import {
  useDeleteUserReviewMutation,
  useGetUserReviewsByUserIdQuery,
} from "../../store/services/userReviewService";
import { Rating } from "react-simple-star-rating";
import UserPostReview from "../../components/createUserReview";
import Moment from "react-moment";
import { HandThumbsUpFill } from "react-bootstrap-icons";
import UserImage from "../../components/UserImage";
import "./UserContactProfile.css";
import ShareButton from "../../components/ShareBtn";
import toast from "react-hot-toast";
import { MdIosShare } from "react-icons/md";
import { useGetDistanceToUserMutation } from "../../store/services/homeProducts";

const UserContactProfile = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

const [link_copied, setlink_copied] = useState(false)

  const _id = useSelector((state) => state.authReducer.user?._id);
  const latlong = useSelector((state) => state.authReducer.latlong);
  const isAuth = Boolean(useSelector((state) => state.authReducer.token));
  const {
    data: friendrequeststatus,
    isLoading,
    isError,
    refetch: refetchFriendRequestsQuery,
  } = useCheckFriendRequestsQuery({
    userId: _id, // Assuming user object has an 'id' property
    otherUserId: userId, // Replace 'otherUserId' with the actual other user's ID
    skip: !isAuth, // Skip the query if user is not authenticated
  });

  const {
    data: followinguser,

    refetch: refetchfollowingrequest,
  } = useCheckFollowingQuery({
    userId: _id, // Assuming user object has an 'id' property
    followeeId: userId, // Replace 'otherUserId' with the actual other user's ID
    skip: !isAuth, // Skip the query if user is not authenticated
  });

  const [state, zimAction] = useZIM();
  const { data, isFetching } = useGetUserByIdQuery(userId);
  const { data: userreviews, isFetching: userreviewfetching } =
    useGetUserReviewsByUserIdQuery(userId);
  const [selectedindex, setselectedindex] = useState(0);
  const [distanceaway, setdistanceaway] = useState(0);
  const [getDistanceToUser, { error }] = useGetDistanceToUserMutation();

  const getmaindistance = async () => {
    
    try {
      const response = await getDistanceToUser({ userId,  latitude:latlong?.latitude,
        longitude:latlong?.longitude }).unwrap();
      setdistanceaway(response.distance); // Update the state with the distance
    } catch (err) {
      console.error('Failed to fetch distance:', err);
    }
  };




  const sendMessage = (msg) => {
    if (!isAuth) {
      navigate("/signin");
      return;
    }
    zimAction
      .sendChatMessage(
        0,
        "You are now Connected With Each Other",
        userId,
        false
      )
      .then(() => {
        setTimeout(() => {
          navigate("/chat", { state: { mainchatid: userId } });
        }, 200);
      });
  };

  const [deleteReview] = useDeleteUserReviewMutation();

  const handleDeleteReview = async (reviewId) => {
    try {
      await deleteReview(reviewId).unwrap();
      // Handle success (e.g., show a success message)
    } catch (error) {
      // Handle error (e.g., show an error message)
    }
  };

  useEffect(() => {
    if (navigator.geolocation && data) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            //If granted then you can directly call your function here
           getmaindistance()
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
           getmaindistance()
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [data]);

  const [sendFriendRequestMutation] = useSendFriendRequestMutation();
  const handleSendFriendRequest = () => {
    if (!isAuth) {
      navigate("/signin");
      return;
    }

    const requestData = { senderId: _id, receiverId: userId }; // Customize as needed
    sendFriendRequestMutation(requestData)
      .unwrap()
      .then((data) => {
        refetchFriendRequestsQuery();
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };

  // Example usage of useAcceptFriendRequestMutation
  const [acceptFriendRequestMutation] = useAcceptFriendRequestMutation();
  const handleAcceptFriendRequest = () => {
    acceptFriendRequestMutation(friendrequeststatus.requestId)
      .unwrap()
      .then((data) => {
        zimAction
          .sendChatMessage(
            0,
            "You are now Connected With Each Other",
            userId,
            false
          )
          .then(() => {
            console.log("ok h");
          });
        refetchFriendRequestsQuery();
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };

  // Example usage of useDeclineFriendRequestMutation
  const [declineFriendRequestMutation] = useDeclineFriendRequestMutation();
  const [acceptFollowerMutation] = useAcceptFollowMutation();
  const [removeFollowerMutation] = useRemoveFollowerMutation();
  const handleDeclineFriendRequest = () => {
    declineFriendRequestMutation(friendrequeststatus.requestId)
      .unwrap()
      .then((data) => {
        refetchFriendRequestsQuery();
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };
  const addFollower = () => {
    acceptFollowerMutation({
      senderId: _id, // Assuming user object has an 'id' property
      receiverId: userId,
    })
      .unwrap()
      .then((data) => {
        refetchfollowingrequest();
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };
  const removeFollower = () => {
    removeFollowerMutation({
      userId: _id, // Assuming user object has an 'id' property
      followerId: userId,
    })
      .unwrap()
      .then((data) => {
        refetchFriendRequestsQuery();
        refetchfollowingrequest();
      })
      .catch((error) => {
        console.warn("error", error);
      });
  };

  const [deleteFriendRequestMutation] = useDeleteFriendRequestMutation();
  const handleDeleteFriendRequest = () => {
    deleteFriendRequestMutation({ userId: _id, receiverId: userId }) // Pass the required parameters
      .unwrap()
      .then((data) => {
        refetchFriendRequestsQuery();
      })
      .catch((error) => {
        // Handle error
        console.error("Error deleting friend request:", error);
      });
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    //   <body className="fix">
    //     <section className="product__details-area">
    //       <div className="container">
    //         <div className="row">
    //           <div className="col-lg-6">
    //             <div className="product__details-images-wrap">
    //               <div className="tab-content" id="myTabContent">
    //                 <div
    //                   className="tab-pane show active"
    //                   id="itemOne-tab-pane"
    //                   role="tabpanel"
    //                   aria-labelledby="itemOne-tab"
    //                   tabindex="0"
    //                 >
    //                   <a
    //                     href="assets/img/products/products_img01.jpg"
    //                     className="popup-image"
    //                   >
    //                     <img
    //                       src={`https://api.petzoy.in/assets/${data?.picturePath}`}
    //                       alt="img"
    //                     />
    //                   </a>
    //                 </div>
    //                 <div
    //                   className="tab-pane"
    //                   id="itemTwo-tab-pane"
    //                   role="tabpanel"
    //                   aria-labelledby="itemTwo-tab"
    //                   tabindex="0"
    //                 >
    //                   <a
    //                     href="assets/img/products/products_img02.jpg"
    //                     className="popup-image"
    //                   >
    //                     <img
    //                       src="assets/img/products/products_img02.jpg"
    //                       alt="img"
    //                     />
    //                   </a>
    //                 </div>
    //                 <div
    //                   className="tab-pane"
    //                   id="itemThree-tab-pane"
    //                   role="tabpanel"
    //                   aria-labelledby="itemThree-tab"
    //                   tabindex="0"
    //                 >
    //                   <a
    //                     href="assets/img/products/products_img03.jpg"
    //                     className="popup-image"
    //                   >
    //                     <img
    //                       src="assets/img/products/products_img03.jpg"
    //                       alt="img"
    //                     />
    //                   </a>
    //                 </div>
    //                 <div
    //                   className="tab-pane"
    //                   id="itemFour-tab-pane"
    //                   role="tabpanel"
    //                   aria-labelledby="itemFour-tab"
    //                   tabindex="0"
    //                 >
    //                   <a
    //                     href="assets/img/products/products_img04.jpg"
    //                     className="popup-image"
    //                   >
    //                     <img
    //                       src="assets/img/products/products_img04.jpg"
    //                       alt="img"
    //                     />
    //                   </a>
    //                 </div>
    //               </div>
    //               <ul className="nav nav-tabs" id="myTab" role="tablist">
    //                 <li className="nav-item" role="presentation">
    //                   <button
    //                     className="nav-link active"
    //                     id="itemOne-tab"
    //                     data-bs-toggle="tab"
    //                     data-bs-target="#itemOne-tab-pane"
    //                     type="button"
    //                     role="tab"
    //                     aria-controls="itemOne-tab-pane"
    //                     aria-selected="true"
    //                   >
    //                     <img
    //                       src={`https://api.petzoy.in/assets/${data?.picturePath}`}
    //                       alt="img"
    //                     />
    //                   </button>
    //                 </li>
    //                 {/* <li className="nav-item" role="presentation">
    //                   <button
    //                     className="nav-link"
    //                     id="itemTwo-tab"
    //                     data-bs-toggle="tab"
    //                     data-bs-target="#itemTwo-tab-pane"
    //                     type="button"
    //                     role="tab"
    //                     aria-controls="itemTwo-tab-pane"
    //                     aria-selected="false"
    //                   >
    //                     <img
    //                       src="assets/img/products/products_img02.jpg"
    //                       alt="img"
    //                     />
    //                   </button>
    //                 </li>
    //                 <li className="nav-item" role="presentation">
    //                   <button
    //                     className="nav-link"
    //                     id="itemThree-tab"
    //                     data-bs-toggle="tab"
    //                     data-bs-target="#itemThree-tab-pane"
    //                     type="button"
    //                     role="tab"
    //                     aria-controls="itemThree-tab-pane"
    //                     aria-selected="false"
    //                   >
    //                     <img
    //                       src="assets/img/products/products_img03.jpg"
    //                       alt="img"
    //                     />
    //                   </button>
    //                 </li>
    //                 <li className="nav-item" role="presentation">
    //                   <button
    //                     className="nav-link"
    //                     id="itemFour-tab"
    //                     data-bs-toggle="tab"
    //                     data-bs-target="#itemFour-tab-pane"
    //                     type="button"
    //                     role="tab"
    //                     aria-controls="itemFour-tab-pane"
    //                     aria-selected="false"
    //                   >
    //                     <img
    //                       src="assets/img/products/products_img04.jpg"
    //                       alt="img"
    //                     />
    //                   </button>
    //                 </li> */}
    //               </ul>
    //             </div>
    //           </div>
    // <div className="col-lg-6">
    //   <div className="product__details-content">
    //     <div className="product-desc-wrap">
    //       <ul className="nav nav-tabs" id="myTab2" role="tablist">
    //         <li className="nav-item" role="presentation">
    //           <button
    //             onClick={() => setselectedindex(0)}
    //             className="nav-link active"
    //             id="description-tab"
    //             data-bs-toggle="tab"
    //             data-bs-target="#description-tab-pane"
    //             type="button"
    //             role="tab"
    //             aria-controls="description-tab-pane"
    //             aria-selected="true"
    //           >
    //             Description
    //           </button>
    //         </li>
    //         <li className="nav-item" role="presentation">
    //           <button
    //             onClick={() => setselectedindex(1)}
    //             className="nav-link"
    //             id="reviews-tab"
    //             data-bs-toggle="tab"
    //             data-bs-target="#reviews-tab-pane"
    //             type="button"
    //             role="tab"
    //             aria-controls="reviews-tab-pane"
    //             aria-selected="false"
    //           >
    //             Reviews
    //           </button>
    //         </li>
    //       </ul>
    //     </div>

    //               {selectedindex == 0 && (
    //                 <>
    //                   <Friend
    //                     friendId={data?._id}
    //                     name={`${data?.firstName}`}
    //                     subtitle={data?.occupation}
    //                     userPicturePath={data?.picturePath}
    //                   />
    //                   {/* <h2 className="title">Meow Mix Seafood Medley Dry Cat Food, 3.15-Pound</h2>
    //                       <div className="product__reviews-wrap">
    //                           <div className="product__reviews">
    //                               <div className="rating">
    //                                   <i className="fas fa-star"></i>
    //                                   <i className="fas fa-star"></i>
    //                                   <i className="fas fa-star"></i>
    //                                   <i className="fas fa-star"></i>
    //                                   <i className="fas fa-star"></i>
    //                               </div>
    //                               <span>(2 Reviews)</span>
    //                           </div>
    //                           <div className="product__code">
    //                               <span>SKU: <strong>CAT4502</strong></span>
    //                           </div>
    //                       </div> */}
    //                   {/* <h4 className="price">$29.00</h4> */}
    //                   <span className="contact-size-title">User Distance:</span>
    //                   <p>{distanceaway.toFixed(2)} Km away </p>
    //                   <div className="line__devider" />
    //                   <span className="contact-size-title">User Services:</span>
    //                   <p>
    //                     {" "}
    //                     {data?.providedServices?.map((item, index) => (
    //                       <React.Fragment key={index}>
    //                         <i class="fa-solid fa-star"></i>{" "}
    //                         <small>
    //                           {item.name}
    //                           <br />
    //                         </small>{" "}
    //                         -- <span>{item.title}</span>
    //                         <br />
    //                       </React.Fragment>
    //                     ))}
    //                   </p>
    //                   {/* {.map((item, index) => item.name)} */}

    //                   {/* <span className="contact-size-title">Accepted Pets:</span>
    //               <p>   {data?.serviceTypesProvided?.map((item, index) => (
    //   <React.Fragment key={index}>
    //    <i class="fa-solid fa-star"></i> {item.petType.petType}<br/>

    //   </React.Fragment>
    // ))}</p> */}

    //                   <div className="product__size-wrap">
    //                     <span className="size-title">Accepted Pets:</span>
    //                     <ul className="list-wrap">
    //                       {data?.serviceTypesProvided?.map((item, index) => (
    //                         <React.Fragment key={index}>
    //                           <li>
    //                             <button>
    //                               <i class="fa-solid fa-star"></i>{" "}
    //                               {item.petType.petType}
    //                               <br />
    //                             </button>
    //                           </li>
    //                         </React.Fragment>
    //                       ))}
    //                     </ul>
    //                   </div>
    //                   <span className="contact-size-title">User Pets :</span>
    //                   <MyPetListWidget
    //                     userId={userId}
    //                     isProfile
    //                     onPress={(e) =>
    //                       navigate("/contactprofile", {
    //                         state: { userId: e._id },
    //                       })
    //                     }
    //                   />

    // {userId != _id && (
    //   <div className="product__details-qty mt-20">
    //     {friendrequeststatus?.pendingRequest &&
    //       friendrequeststatus?.receivedRequest && (
    //         <>
    //           <a
    //             onClick={() => handleDeclineFriendRequest()}
    //             className="reject-btn"
    //           >
    //             Reject
    //           </a>
    //           <a
    //             onClick={() => handleAcceptFriendRequest()}
    //             className="accept-btn"
    //           >
    //             Accept
    //           </a>
    //         </>
    //       )}

    //     {friendrequeststatus?.sentRequest == true ? (
    //       <>
    //         {friendrequeststatus.pendingRequest &&
    //           friendrequeststatus.receivedRequest == false && (
    //             <a
    //               onClick={() => handleDeleteFriendRequest()}
    //               className="reject-btn"
    //             >
    //               Cancel Request
    //             </a>
    //           )}

    //         {friendrequeststatus.requestStatus ==
    //           "declined" && (
    //           <>
    //             <p className="reject-btn">Request Declined</p>
    //           </>
    //         )}
    //         {friendrequeststatus.requestStatus == "accepted" &&
    //           !friendrequeststatus.pendingRequest && (
    //             <>
    //               {followinguser?.isFollowing ? (
    //                 <button
    //                   onClick={() => removeFollower()}
    //                   className="reject-btn"
    //                 >
    //                   Following
    //                 </button>
    //               ) : data.private ? (
    //                 <a
    //                   onClick={() => handleSendFriendRequest()}
    //                   className="add-btn"
    //                 >
    //                   Request Follow
    //                 </a>
    //               ) : (
    //                 <a
    //                   onClick={() => addFollower()}
    //                   className="add-btn"
    //                 >
    //                   Follow
    //                 </a>
    //               )}
    //               <a
    //                 onClick={() => sendMessage()}
    //                 className="add-btn"
    //               >
    //                 Chat Now
    //               </a>
    //             </>
    //           )}
    //       </>
    //     ) : data.private ? (
    //       <a
    //         onClick={() => handleSendFriendRequest()}
    //         className="add-btn"
    //       >
    //         Request Follow
    //       </a>
    //     ) : (
    //       <>
    //         {followinguser?.isFollowing ? (
    //           <button
    //             onClick={() => removeFollower()}
    //             className="reject-btn"
    //           >
    //             Following
    //           </button>
    //         ) : (
    //           <a
    //             onClick={() => addFollower()}
    //             className="add-btn"
    //           >
    //             Follow
    //           </a>
    //         )}
    //         <a
    //           onClick={() => sendMessage()}
    //           className="add-btn"
    //         >
    //           Chat Now
    //         </a>
    //       </>
    //     )}
    //   </div>
    // )}
    //                   {/* <a href="product-details.html" className="buy-btn">Buy it Now</a> */}

    // <div className="product__details-bottom">
    //   <ul className="list-wrap">
    //     <li className="product__details-social">
    //       <span className="title">Share :</span>
    //       <ul className="list-wrap">
    //         <li>
    //           <a
    //             href="https://www.facebook.com/"
    //             target="_blank"
    //           >
    //             <i className="fab fa-facebook-f"></i>
    //           </a>
    //         </li>
    //         <li>
    //           <a href="https://twitter.com" target="_blank">
    //             <i className="fab fa-twitter"></i>
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="https://www.whatsapp.com/"
    //             target="_blank"
    //           >
    //             <i className="fab fa-whatsapp"></i>
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="https://www.instagram.com/"
    //             target="_blank"
    //           >
    //             <i className="fab fa-instagram"></i>
    //           </a>
    //         </li>
    //         <li>
    //           <a
    //             href="https://www.youtube.com/"
    //             target="_blank"
    //           >
    //             <i className="fab fa-youtube"></i>
    //           </a>
    //         </li>
    //       </ul>
    //     </li>
    //   </ul>
    // </div>
    //                 </>
    //               )}

    // {selectedindex == 1 && (
    //   <>
    //     {isAuth &&
    //       !userreviews.some(
    //         (review) => review?.createdBy?._id === _id
    //       ) &&
    //       _id != userId && <UserPostReview id={userId} />}
    //     <div className="comments-wrap">
    //       <h3 className="comments-wrap-title">
    //         {userreviews.length} Reviews
    //       </h3>
    //       <div className="latest-comments">
    //         <ul className="list-wrap">
    //           <li>
    //             {userreviews &&
    //               userreviews.map((reply) => (
    //                 <div className="comments-box">
    //                   <UserImage
    //                     image={reply?.createdBy?.picturePath}
    //                     size="55px"
    //                   />
    //                   <div className="comments-text">
    //                     <div className="avatar-name">
    //                       <h6 className="name">
    //                         {reply?.createdBy?.firstName}
    //                       </h6>
    //                       <span className="date ">
    //                         <Moment
    //                           fromNow
    //                           style={{
    //                             color: "#505050",
    //                             marginLeft: "5px",
    //                           }}
    //                         >
    //                           {reply?.createdAt}
    //                         </Moment>
    //                       </span>
    //                     </div>

    //                     <Rating
    //                       readonly
    //                       SVGstyle={{ display: "inline" }}
    //                       initialValue={reply?.rating}
    //                       size={20}
    //                     />
    //                     <p>{reply?.comment}</p>
    //                     {reply?.createdBy?._id === _id && (
    //                       <button
    //                         onClick={() =>
    //                           handleDeleteReview(reply._id)
    //                         }
    //                       >
    //                         Delete
    //                       </button>
    //                     )}
    //                   </div>
    //                 </div>
    //               ))}
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </>
    // )}

    //               {/* <div className="product__details-checkout">
    //                           <span className="title">Guaranteed Safe Checkout</span>
    //                           <img src="assets/img/products/card.png" alt=""/>
    //                       </div> */}
    //             </div>
    //           </div>
    //         </div>
    //         {/* <div className="row">
    //               <div className="col-12">
    //                   <div className="product-desc-wrap">
    //                       <ul className="nav nav-tabs" id="myTab2" role="tablist">
    //                           <li className="nav-item" role="presentation">
    //                               <button className="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description-tab-pane" type="button" role="tab" aria-controls="description-tab-pane" aria-selected="true">Description</button>
    //                           </li>
    //                           <li className="nav-item" role="presentation">
    //                               <button className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews-tab-pane" type="button" role="tab" aria-controls="reviews-tab-pane" aria-selected="false">Reviews</button>
    //                           </li>
    //                       </ul>
    //                       <div className="tab-content" id="myTabContent2">
    //                           <div className="tab-pane fade show active" id="description-tab-pane" role="tabpanel" aria-labelledby="description-tab" tabindex="0">
    //                               <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet ante. ibero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.ea commodo consat. Duis aute irure dolor in reprehenderit   volup tate velitesse cillum dolore euy elit ale ruin irure dolor.uis aute irure dolor in reprehenderit n volup tate velit esse cillum,</p>
    //                               <p>habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet bero sit amet uam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.ea commodo consat.</p>
    //                           </div>
    //                           <div className="tab-pane fade" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabindex="0">
    //                               <div className="product-desc-review">
    //                                   <div className="product-desc-review-title mb-15">
    //                                       <h5 className="title">Customer Reviews (0)</h5>
    //                                   </div>
    //                                   <div className="left-rc">
    //                                       <p>No reviews yet</p>
    //                                   </div>
    //                                   <div className="right-rc">
    //                                       <a href="#">Write a review</a>
    //                                   </div>
    //                               </div>
    //                           </div>
    //                       </div>
    //                   </div>
    //               </div>
    //           </div> */}
    //       </div>
    //     </section>
    //   </body>

    // ! Changes
    <div className="mainContainer">
      <div className="containerFirst">
        <div className="mainImageContainer">
          <img
            src={`https://api.petzoy.in/assets/${data?.picturePath}`}
            alt="img"
          />
        </div>
        <div className="userInfoContainer">
          <div className="userInfo">
            <h1>{`${data?.firstName}`}</h1>

          {data?.private && !followinguser?.isFollowing ? (
            <></>
          ) : (

<div className="product__details-qty followChatNowBtn">
             
             <a
                                     onClick={() => navigate(`/social/${data?._id}`)}
                                     className="accept-btn"
                                   >
                                     Social profile
                                   </a>
                                   </div>)}

            <span className="contact-size-title">User Distance :</span>
            <p>{distanceaway.toFixed(2)} Km away </p>
          </div>
          {data?.private && !followinguser?.isFollowing ? (
            <></>
          ) : (
            <div className="userPetsSection">
              <span className="contact-size-title">User Pets :</span>
              <MyPetListWidget
                userId={userId}
                isProfile
                onPress={(e) =>
                  navigate(`/contactprofile/${e._id}`, {
                    state: { userId: e._id },
                  })
                }
              />


            </div>
          )}
        </div>
        <div className="petTagsContainer">
          <div className="interactionContainer">
            {userId != _id && (
              <div className="product__details-qty followChatNowBtn">
                {friendrequeststatus?.pendingRequest &&
                  friendrequeststatus?.receivedRequest && (
                    <>
                      <a
                        onClick={() => handleDeclineFriendRequest()}
                        className="reject-btn"
                      >
                        Reject
                      </a>
                      <a
                        onClick={() => handleAcceptFriendRequest()}
                        className="accept-btn"
                      >
                        Accept
                      </a>
                    </>
                  )}

                {friendrequeststatus?.sentRequest == true ? (
                  <>
                    {friendrequeststatus.pendingRequest &&
                      friendrequeststatus.receivedRequest == false && (
                        <a
                          onClick={() => handleDeleteFriendRequest()}
                          className="reject-btn"
                        >
                          Cancel Request
                        </a>
                      )}

                    {friendrequeststatus.requestStatus == "declined" && (
                      <>
                        <p className="reject-btn">Request Declined</p>
                      </>
                    )}
                    {friendrequeststatus.requestStatus == "accepted" &&
                      !friendrequeststatus.pendingRequest && (
                        <>
                          {followinguser?.isFollowing ? (
                            <button
                              onClick={() => removeFollower()}
                              className="reject-btn"
                            >
                              Following
                            </button>
                          ) : data.private ? (
                            <a
                              onClick={() => handleSendFriendRequest()}
                              className="add-btn"
                            >
                              Request Follow
                            </a>
                          ) : (
                            <a
                              onClick={() => addFollower()}
                              className="add-btn"
                            >
                              Follow
                            </a>
                          )}
                          <a onClick={() => sendMessage()} className="add-btn">
                            Chat Now
                          </a>
                        </>
                      )}
                  </>
                ) : data.private ? (
                  <a
                    onClick={() => handleSendFriendRequest()}
                    className="add-btn"
                  >
                    Request Follow
                  </a>
                ) : (
                  <>
                    {followinguser?.isFollowing ? (
                      <button
                        onClick={() => removeFollower()}
                        className="reject-btn"
                      >
                        Following
                      </button>
                    ) : (
                      <a onClick={() => addFollower()} className="add-btn">
                        Follow
                      </a>
                    )}
                    <a onClick={() => sendMessage()} className="add-btn">
                      Chat Now
                    </a>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="product__size-wrap">
            <span className="size-title">Accepted Pets:</span>
            <ul className="list-wrap">
              {data?.private && !followinguser?.isFollowing ? (
                <li>
                  <button>
                    <i class="fa-solid fa-star"></i> This account is private
                    <br />
                  </button>
                </li>
              ) : (
                data?.serviceTypesProvided?.map((item, index) => (
                  <React.Fragment key={index}>
                    <li>
                      <button>
                        <i class="fa-solid fa-star"></i> {item.petType.petType}
                        <br />
                      </button>
                    </li>
                  </React.Fragment>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="shareContainer">
        <ul className="list-wrap">
          <li className="product__details-social">
            <span className="title" id="title">
              Share Profile :
            </span>
            <ul className="list-wrap" id="list-wrap">
              <li>
                <a
                  onClick={() => {
                    setlink_copied(true)
                    navigator.clipboard.writeText(
                      `https://www.petzoy.in/userconnect/${userId}`
                    );
                    toast.success('Link Copied !');
                  }}
                >
                  <i class={link_copied ? "fa-regular fa-circle-check" :"fa-regular fa-copy"}></i>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <div className="share-button-container">
      
      </div>
        {/* <div className="userCalendar">
          <div id="calendarTitle">Member Since Jul, 2024</div>
        </div> */}
      </div>
      <div className="discriptionContainer">
        <div className="userServicesContainer">
          <div className="discriptionHeading">
            <span>Discription:</span>
          </div>
          {data?.private && !followinguser?.isFollowing ? (
            <></>
          ) : (
            <>
            {data?.donate && isAuth &&
<div className="product__details-qty followChatNowBtn">
             
             <a
                                     onClick={() => navigate("/donateshops", { state: { selected_member: data } })}
                                     className="accept-btn"
                                   >
                                     Donate Now
                                   </a>
                                   </div>}
              {" "}
              <span className="contact-size-title">User Services:</span>
              <p>
                {" "}
                {data?.providedServices?.map((item, index) => (
                  <React.Fragment key={index}>
                    <i class="fa-solid fa-star"></i>{" "}
                    <small>
                      {item.name}
                      <br />
                    </small>{item.title ? ( <span>( {item.title} )</span>):null}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </>
          )}{" "}
        </div>
      </div>
      {data?.private && !followinguser?.isFollowing ? (
        <></>
      ) : (
        <div className="reviewsContainer">
          <div className="reviewsPostContainer">
            <div className="reviewsHeading">
              <span>Review:</span>
            </div>
            <div className="reviewsMainContainer">
              <div className="reviewsMainContainerOne">
                {isAuth &&
                  !userreviews?.some(
                    (review) => review?.createdBy?._id === _id
                  ) &&
                  _id != userId && <UserPostReview id={userId} />}
              </div>
              <div className="reviewsMainContainerTwo">
                <div className="comments-wrap">
                  <h3 className="comments-wrap-title">
                    {userreviews?.length} Reviews
                  </h3>
                  <div className="latest-comments">
                    <ul className="list-wrap">
                      <li>
                        {userreviews &&
                          userreviews?.map((reply) => (
                            <div className="comments-box">
                              <UserImage
                                image={reply?.createdBy?.picturePath}
                                size="55px"
                              />
                              <div className="comments-text">
                                <div className="avatar-name">
                                  <h6 className="name">
                                    {reply?.createdBy?.firstName}
                                  </h6>
                                  <span className="date ">
                                    <Moment
                                      fromNow
                                      style={{
                                        color: "#505050",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {reply?.createdAt}
                                    </Moment>
                                  </span>
                                </div>

                                <Rating
                                  readonly
                                  SVGstyle={{ display: "inline" }}
                                  initialValue={reply?.rating}
                                  size={20}
                                />
                                <p>{reply?.comment}</p>
                                {reply?.createdBy?._id === _id && (
                                  <button
                                    onClick={() =>
                                      handleDeleteReview(reply._id)
                                    }
                                  >
                                    Delete
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserContactProfile;
