
import React, { useState } from 'react'
import { useAcceptOrRejectNotificationMutation } from '../../store/services/notificationService';
import { FaUserFriends } from 'react-icons/fa';

const ActionNotification = ({notification}) => {



    const [condition, setcondition] = useState('')

    // Use the mutation hook
    const [acceptOrRejectNotification] = useAcceptOrRejectNotificationMutation();

    const handleAction = async (notificationId, action) => {
        try {
            // Call the mutation to accept or reject the notification
            await acceptOrRejectNotification({ notificationId, action }).unwrap();
            setcondition(`${action}ed`)
            // Optionally, you can handle successful action (e.g., show a message, refetch notifications)
            console.log("Action successfully processed");

        } catch (err) {
            console.error("Failed to process action:", err);
            // Optionally, handle error (e.g., show an error message)
        }
    };



  return (
    
    <div
    key={notification._id}
    className={`px-4 py-3 hover:bg-gray-50 ${
      notification.status == "unread" ? "bg-blue-50" : ""
    }`}
  >
    <div className="flex items-center">
      <div className="flex-shrink-0 mr-3">
        <div className="relative">
          <img
            src={"https://api.petzoy.in/assets/"+notification?.sender?.picturePath}
            alt="User avatar"
            className="w-12 h-12 rounded-full"
          />
          <div className={`absolute -bottom-1 -right-1 bg-blue-500 rounded-full p-1`}>
           {notification?.notificationType == "pet_request" ?<FaUserFriends className="text-white" />:null} 
          </div>
        </div>
      </div>
      <div className="flex-grow">
        <p className="text-sm text-gray-900">
          <span className="font-semibold">{notification?.sender?.firstName}</span>
          <br/>
          {notification?.message}
        </p>
        <p className="text-xs text-gray-500 mt-1">
        {notification?.createdAt?.split("T")[0]} 
        </p>
        {(notification?.notificationType !== "post" && 
     notification?.notificationType !== "petopedia" && 
     notification?.action == null) ? (
        condition ? (

            <button
              onClick={() =>  { handleAction(notification?._id, 'accept') }}
              className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 transition-colors duration-200"
            >
              Confirm
            </button>
        ) : (
          <div className="mt-2 flex space-x-2">
            <button
              onClick={() =>  { handleAction(notification?._id, 'accept') }}
              className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 transition-colors duration-200"
            >
              Confirm
            </button>
            <button
              onClick={() => { handleAction(notification?._id, 'reject') }}
              className="px-4 py-2 bg-gray-200 text-gray-900 text-sm font-medium rounded-md hover:bg-gray-300 transition-colors duration-200"
            >
              Delete
            </button>
          </div>
        )
    ) : null
}

      </div>
      {/* <div className="flex-shrink-0 ml-2">
        <button
          onClick={() => markAsRead(notification.id)}
          className="text-gray-400 hover:text-gray-600 p-1 rounded-full hover:bg-gray-100"
        >
          <FaEllipsisH />
        </button>
      </div> */}
    </div>
  </div>
  )
}

export default ActionNotification
