import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
  Routes,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";

import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useMemo } from "react";



import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { themeSettings } from "./theme";
import Home from "./pages/Home";
import Contactus from "./pages/ContactUs";
import Commercial from "./pages/Commerical";
import Login from "./pages/Auth/Login";
import AboutUs from "./pages/AboutUs";
import HomePage from "./pages/homePage";
import ProfilePage from "./pages/profilePage";
import MyPetProfile from "./pages/mypetprofile";
import PetProfile from "./pages/petprofile";
import NewPost from "./components/createpost";
import Dashboard from "./components/Dashboard/dashboard";
import ChatScreen from "./pages/Chat/Chat";
import PostPage from "./pages/DiscussionDetails/PostPage";
import CommercialDetails from "./pages/CommericalDetails";
import { useZIM } from "./hooks/zim";
import ContactProfile from "./pages/ContactProfile";
import ServicesUsers from "./pages/ServicesUsers";
import EditProfile from "./pages/EditProfile/EditProfile";
import WhiteHeader from "./components/WhiteHeader";
import Footer from "./components/Footer/Footer";
import Register from "./pages/Auth/Register";
import UserContactProfile from "./pages/ContactProfile/UserContactProfile";
import { Toaster } from "react-hot-toast";
import AllProducts from "./pages/AllProducts";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Cart from "./pages/Cart/Cart";
import 'bootstrap/dist/css/bootstrap.min.css';
import Payment from "./pages/payment/Payment";
import UserOrderDetails from "./pages/payment/UserOrderDetails";
import ShoppingPage from "./pages/ShoppingPage";
import BlogDetails from "./pages/Blogs/BlogDetails";
import DynamicPages from "./pages/DynamicPages/DynamicPages";
import AllNearbyPets from "./pages/AllNearbyPets/AllNearbyPets";
import AllServiceUsers from "./pages/AllServiceUsers/AllServiceUsers";
import Petopedia from "./components/Dashboard/Components/Petopedia";
import PostComment from "./pages/DiscussionDetails/PostComment";
import FloatingButton from "./components/FloatingButton/FloatingButton";
import UserSocialProfile from "./pages/UserSocialProfile/UserSocialProfile";
import DonateShop from "./pages/ShoppingPage/DonateShop";
import Notifications from "./pages/Notifications/Notifications";

const Layout = () => {
  return (
    <div>
      <WhiteHeader />
      <Outlet />
      <Footer />
      <FloatingButton />
    </div>
  );
};
const App = () => {
  const [state, zimAction] = useZIM();
  const mode = useSelector((state) => state.authReducer.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const location = useLocation();
  const isAuth = Boolean(useSelector((state) => state.authReducer.token));
  const user = useSelector((state) => state.authReducer.user);


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [location]);

  const mainlocation = useLocation();
  const currentUrl = mainlocation.pathname;


  useEffect(() => {
    if (currentUrl == '/signin' || currentUrl == '/register') {
      const configuration = {
        widgetId: "3469666c744d393939343437",
        tokenAuth: "429831TB7CjwrFSQg66daf4b6P1",
        identifier: "", // Set initially to empty
        exposeMethods: true,
        // captchaRenderId: captchaRenderId.current?.id || '',
        success: (data) => {
          console.log('success response', data);
        },
        failure: (error) => {
          console.log('failure reason', error);
        },
      };

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://control.msg91.com/app/assets/otp-provider/otp-provider.js';
      script.onload = () => {
        if (window.initSendOTP) {
          window.initSendOTP(configuration);
        } else {
          console.error('initSendOTP function is not available.');
        }
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }
  }, []);



  useEffect(() => {
    if (isAuth) {
      if (!state.isLogin) {
        zimAction
          .login({ userID: user._id, userName: `${user.firstName}` })
          .then(() => {
            zimAction.initEvent();
            zimAction.updateUserInfo(`${user.firstName}`, `https://api.petzoy.in/assets/${user.picturePath}`);

          });

        console.warn("not log in hai")
      } else {
        console.warn("logged in hai")

      }


    }

  }, [])



  return (
    // <BrowserRouter>

    <ThemeProvider theme={theme}>
      <Toaster position="top-right" />
      <CssBaseline />
      <Routes>

        <Route path="/" element={<Layout />}>
          <Route path="signin" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route index element={<Home />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="contactus" element={<Contactus />} />
          <Route path="commerical" element={<Commercial />} />
          <Route path="commericaldetails/:_id" element={<CommercialDetails />} />
          <Route path="social/:_id" element={<UserSocialProfile />} />
          <Route path="allserviceuser/:_id" element={<AllServiceUsers />} />
          <Route path="blog-details/:id" element={<BlogDetails />} />
          <Route path="contactprofile/:userId" element={<ContactProfile />} />
          <Route path="userconnect/:userId" element={<UserContactProfile />} />
          <Route path="userbyservice" element={<ServicesUsers />} />
          <Route path="allnearbypets" element={<AllNearbyPets />} />
          <Route path="allproducts" element={<AllProducts />} />
          <Route path="shops" element={<ShoppingPage />} />
          <Route path="donateshops" element={<DonateShop />} />
          <Route path="cart" element={<Cart />} />
          <Route path="paymentgateway" element={<Payment />} />
          <Route path="user-order-details/:id" element={<UserOrderDetails />} />
          <Route path="product_details/:mainid" element={<ProductDetails />} />
          <Route path="editprofile" element={isAuth ? <EditProfile /> : <Navigate to="/signin" />} />
          <Route path="docs/:name" element={<DynamicPages />} />

          <Route
            path="/chat"
            element={isAuth ? <ChatScreen /> : <Navigate to="/signin" />}
          />
          <Route
            path="/home"
            element={isAuth ? <HomePage /> : <Navigate to="/signin" />}
          />
          <Route
            path="/profile"
            element={isAuth ? <ProfilePage /> : <Navigate to="/signin" />}
          />
          <Route
            path="/mypetprofile"
            element={isAuth ? <MyPetProfile /> : <Navigate to="/signin" />}
          />
          <Route
            path="/petprofile"
            element={isAuth ? <PetProfile /> : <Navigate to="/signin" />}
          />
          <Route
            path="/notifications"
            element={isAuth ? <Notifications /> : <Navigate to="/signin" />}
          />
          <Route
            path="discussiondashboard"
            element={<Petopedia user={user} />}
          />
          <Route
            path="disc"
            element={<Dashboard user={user} />}
          />

          <Route
            path="/new-post"
            element={<NewPost user={user} />}
          />
          <Route
            path="/post/:postId"
            element={<PostComment user={user} />}
          />


        </Route>


      </Routes></ThemeProvider>

  )
};

// function App() {
//   return (
//     <div className="font-bodyFont">
//       <RouterProvider router={router} />
//     </div>
//   );
// }

export default App;
