import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Modal,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  Card,
  CardActions,
  CardContent,
  Switch,
  useMediaQuery,
  styled,
  FormControlLabel,
  MenuItem,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPetsType } from "../../store/reducers/authReducer";
import FlexBetween from "../../components/FlexBetween";
import { useNavigate } from "react-router";
import UserImage from "../../components/UserImage";
import { useUpdateProfileMutation } from "../../store/services/authService";
import { Android12Switch } from "../../components/AndroidSwitch";
import {
  useRemovePetMutation,
  useUpdatePetProfileMutation,
  useUpdatePetUserImgMutation,
  useUpdatePetUserMutation,
} from "../../store/services/allpetService";
import { DeleteForeverOutlined, EditOutlined, ImageOutlined } from "@mui/icons-material";
import Cropper from "react-easy-crop";
import toast from "react-hot-toast";

const EditPetProfileModal = ({ open, setOpen, userdata }) => {
  const { _id, picturePath } = useSelector((state) => state.authReducer.user);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();
  const navigate = useNavigate();
console.log(userdata,"main user data")
  const [formdata, setformdata] = useState({
    petType: "",
    petName: "",
    petBreed: "",
    petAge: `2024-01-01`,
    petWeight: "",
    gender: "",
    private: false,
  });
  const [selected_type, setselected_type] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imgfile, setimgfile] = useState(null);

  const [current_addstep, setcurrent_addstep] = useState(0);
  const { palette } = useTheme();

  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPreview(null);
    setformdata({
      petType: "",
      petName: "",
      petBreed: "",
      petAge: "",
      petWeight: "",
      gender: "",
      private: false,
    });
  };

  const [all_services, setall_services] = useState([]);

  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    setformdata({
      petName: userdata?.name,
      petAge: userdata?.age?.split('T')[0],
      petWeight: userdata?.weight,
      gender: userdata?.gender,
      private: userdata?.private,
    });

    setSelectedServices(userdata?.providedServices);
  }, [userdata]);

  const getpet_type = async () => {
    const response = await fetch(
      `https://api.petzoy.in/api/services/services`,
      {
        method: "GET",
        // headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    const petServices = data.filter((service) => service.type == "pet");
    setall_services(petServices);
    // setall_services(data)
  };

  useEffect(() => {
    getpet_type();
  }, []);

  const [updatePetUser] = useUpdatePetUserMutation();

  const handleFormInput = (event) => {
    console.log(event);
    const { name, value } = event.target;
    setformdata({
      ...formdata,
      [name]: value,
    });
  };


  const [removePet] = useRemovePetMutation();

  const handleRemovePet = async () => {
    try {
      const confirmed = window.confirm('Are you sure you want to remove this pet?');
      if (!confirmed) return;

      await removePet({ userId:_id,petId: userdata._id }).unwrap();
      alert('Pet removed successfully!');
      handleClose();
      // Handle any additional logic, such as updating local state or redirecting
    } catch (error) {
      console.error('Failed to remove pet:', error);
      alert('Failed to remove pet. Please try again.');
    }
  };


  const [updateProfile, { isLoading, error }] = useUpdatePetUserImgMutation();
  const handleSave = async () => {

if(!formdata.petName){
  toast.error("Pet Name Required")
  return
}
if(!formdata.petAge){
  toast.error("Pet Age Required")
  return
}

    if (preview) {
      try {
        const croppedBlob = await getCroppedImg(preview, croppedAreaPixels);
        const randomName = `croppedImage_${Math.random()
          .toString(36)
          .substring(2, 15)}.jpg`;
        const croppedFile = new File([croppedBlob], randomName, {
          type: "image/jpeg",
        });

        const formData = new FormData();
        formData.append("userId", userdata._id);
        formData.append("picture", croppedFile);
        formData.append("picturePath", randomName);

        const result = await updateProfile(formData);
        if (result?.data?.resp === true) {
          toast.success("Pet Updated");
        }
        setimgfile(null);
        setPreview(null);
        setimgfile("");
        handleClose();
      } catch (error) {
        console.error("Error uploading image:", error);
      }
      return;
    }
    try {
      const response = await updatePetUser({
        name: formdata.petName,
        age: formdata.petAge,
        gender: formdata.gender,
        userId: _id,
        petId: userdata._id,
        weight: formdata.petWeight,
        private: formdata.private,

        services: JSON.stringify(selectedServices),
      });

      console.log(response.data); // Log the response data

      // Reset form values and close the modal
      setimgfile(null);
      setimgfile("");
      handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error if needed
    }

    handleClose();
  };

  const handleServiceSelection = (service) => {
    const index = selectedServices.findIndex((s) => s._id === service._id);
    if (index === -1) {
      setSelectedServices([...selectedServices, service]);
    } else {
      const updatedServices = [...selectedServices];
      updatedServices.splice(index, 1);
      setSelectedServices(updatedServices);
    }
  };

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  // const [updateProfile, { isLoading, error }] = useUpdateProfileimgMutation(); // Use the mutation hook

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setimgfile(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        bgcolor: "rgba(0,0,0,0)",
        border: "2px solid #000",
        flexDirection: "column",
        boxShadow: 24,
        overflowY: "scroll",
      }}
    >
      <>
        {" "}
        {/* {!isNonMobileScreens && ( */}
          <Box
            sx={{
              width: isNonMobileScreens ? "30%" : "100%",
              maxWidth: "800px",
              bgcolor: palette.primary.main,
              display: "flex",
              boxShadow: 24,
              height: "70px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <i onClick={handleClose} className="fa-solid fa-chevron-left"></i>
            <Typography variant="h4" color={"black"}>
              Edit Pet Profile
            </Typography>
            <i className="fa-solid fa-paw"></i>
          </Box>
        {/* )} */}
        <Box
          sx={{
            width: isNonMobileScreens ? "30%" : "100%",
            maxWidth: "800px",
            bgcolor: "white",

            borderBottomLeftRadius: isNonMobileScreens ? 5 : 0,
            borderBottomRightRadius: isNonMobileScreens ? 5 : 0,
            boxShadow: 24,
            height: isNonMobileScreens ? "auto" : "100%",
            paddingBottom: 1,
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          <>
            <FlexBetween gap="1rem" margin={"10px"}>
              <input type="file" onChange={handleImageUpload} />
              <UserImage image={userdata?.picturePath} size="60px" />
            </FlexBetween>

            {preview && (
              <Box
                sx={{
                  width: "100%",
                  height: "300px", // Fixed height for the cropper container
                  position: "relative",
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: 1,
                  borderRadius: "10px",
                  borderStyle: "dashed",
                  overflow: "hidden",
                }}
              >
                <Cropper
                  image={preview}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  style={{ containerStyle: { width: "100%", height: "100%" } }}
                />
              </Box>
            )}

            {!preview && (
              <>
                <TextField
                  label="Name"
                  value={formdata.petName}
                  type="text"
                  required
                  name="petName"
                  onChange={handleFormInput}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Age (In Yrs.)"
                  value={formdata.petAge}
                  type="date"
                  name="petAge"
                  required
                  onChange={handleFormInput}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Weight (In Kgs)"
                  value={formdata.petWeight}
                  type="number"
                  
                  name="petWeight"
                  onChange={handleFormInput}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  select
                  label="Pet Gender"
                  value={formdata.gender}
                  
                  onChange={handleFormInput}
                  name="gender"
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </TextField>
              </>
            )}
            {!preview && (
              <>
                <FlexBetween>
                  <FlexBetween gap="1rem" margin={"10px"}>
                    <img
                      style={{
                        objectFit: "cover",
                        width: "30px",
                        height: "30px",
                      }}
                      alt="user"
                      src={`https://www.freeiconspng.com/thumbs/lock-icon/lock-icon-11.png`}
                    />
                    <Box>
                      <Typography
                        color={"blac"}
                        variant="h5"
                        fontWeight="500"
                        sx={{
                          "&:hover": {
                            color: palette.primary.light,
                            cursor: "pointer",
                          },
                        }}
                      >
                        Private Account
                      </Typography>
                      <Typography color={"#000"} fontSize="0.75rem">
                        Keep my profile private
                      </Typography>
                    </Box>
                  </FlexBetween>
                  <FormControlLabel
                    control={
                      <Android12Switch
                        onClick={() =>
                          setformdata({
                            ...formdata,
                            private: !formdata.private,
                          })
                        }
                        checked={formdata.private}
                      />
                    }
                    // label="Android 12"
                  />
                </FlexBetween>
              </>
            )}

            {!preview && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "300px",
                    // flexDirection: "row",
                    // justifyContent: "space-evenly",
                    alignItems: "center",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                  gridColumn="span 4"
                >
                  {all_services?.map((service) => (
                    //   <Chip
                    //     key={service.id}
                    //     label={service.name}
                    //     clickable
                    //     onClick={() => handleServiceSelection(service)}
                    //     color={
                    //       selectedServices.find((s) => s._id === service._id)
                    //         ? "primary"
                    //         : "default"
                    //     }
                    //     variant="outlined"
                    //   />

                    <FlexBetween sx={{ width: "100%" }}>
                      <FlexBetween gap="1rem">
                        <UserImage image={service.image} size="55px" />
                        <Box>
                          <Typography
                            color={"black"}
                            variant="h5"
                            fontWeight="500"
                            sx={{
                              "&:hover": {
                                color: palette.primary.light,
                                cursor: "pointer",
                              },
                            }}
                          >
                            {service.name}
                          </Typography>
                          <Typography color={"gray"} fontSize="0.75rem">
                            {service.description}
                          </Typography>
                        </Box>
                      </FlexBetween>
                      <FormControlLabel
                        control={
                          <Android12Switch
                            onClick={() => handleServiceSelection(service)}
                            checked={selectedServices?.find(
                              (s) => s._id === service._id
                            )}
                          />
                        }
                        // label="Android 12"
                      />
                    </FlexBetween>
                  ))}
                </Box>
              </>
            )}
          </>
          

          <Box
            sx={[
              { display: "flex", justifyContent: "space-between" },
              !isNonMobileScreens && {
                position: "absolute",
                bottom: "20px",
                width: "80%",
              },
            ]}
          >
            

         {userdata?.owner == _id &&  <button onClick={() => handleRemovePet()} className="flex items-center space-x-2 group bg-red-50 px-3 py-2 rounded-full transition duration-200">
                      <DeleteForeverOutlined className="h-6 w-6 text-red-500" />
                      <span className="text-red-500">
                        Remove Pet
                      </span>
                    </button>} 
            <Button variant="contained" size="large" onClick={handleSave}>
              {"Save"}
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default EditPetProfileModal;
