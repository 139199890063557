import { Virtual, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import MyPetListWidget from "../../pages/widgets/MyPetListWidget";
import React from "react";

const NearbyUsers = ({ data }) => {

  let i = 1;
  return (
    data?.length > 0 && (
      <Swiper
        navigation={true}
        modules={[Navigation]}
        spaceBetween={20}
        slidesPerView={20}
        // virtual
        Navigation
        className="w-full h-full"
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          1080: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
      >
        {data.map((category, index) => {
          if (i >= 5) {
            i = 1;
          } else {
            i++;
          }
          return (
            <SwiperSlide
              className="w-full h-full overflow-hidden  relative soccer"
              key={index}
              virtualIndex={index}
            >
              <Link
                to={{
                  pathname: `/userconnect/${category._id}`,

                }}
                state={{
                  userId: category._id
                }}
                className="w-full h-full"
              >
                {/* <div className="col-xl-12" key={category._id}>
                  <div className="animal__item animal__item-two shine-animate-item">
                    <div className="animal__thumb animal__thumb-two shine-animate">
                      <a href="animal-details.html"><img src={`https://api.petzoy.in/assets/${category?.picturePath}`} alt="img" /></a>
                      <a href="animal-details.html" className="wishlist"><i className="flaticon-love"></i></a>
                    </div>
                    <div className="animal__content animal__content-two">
                      <h5 className="title"><a href="animal-details.html">{category?.firstName} </a></h5>

                    </div>
                  </div>
                </div> */}

                <div class="main-box-container" id="usermaincard">
                  <div class="box-container">

                    <img src={`https://api.petzoy.in/assets/${category?.picturePath}`} />
                    <h3>{category?.private && <i class="fa-solid fa-lock"></i>} {category?.firstName}</h3>

                    <p>

                      <p>
                        {category?.providedServices?.map((item, index) => (
                          <React.Fragment key={index}>
                            <small>{item.name}</small>{item.title ? ( <span>( {item.title} )</span>):null}
                            <br />


                          </React.Fragment>
                        ))}
                      </p>


                    </p>
                    {category?.pets?.length != 0 && <p>{category?.pets?.length} Pet's Owner</p>}

                    <h6><i class="fa-solid fa-circle-arrow-right"></i> </h6>
                  </div>
                </div>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>
    )
  )

};

export default NearbyUsers;
