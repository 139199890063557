import React, { useState, useEffect } from 'react';
import { FaCheck, FaTimes, FaEllipsisH, FaUserFriends, FaThumbsUp, FaComment, FaShare, FaBirthdayCake, FaVideo, FaGlobeAmericas } from 'react-icons/fa';
import InfoNotification from './InfoNotification';
import ActionNotification from './ActionNotification';
import { useSelector } from 'react-redux';
import { useGetNotificationsQuery } from '../../store/services/notificationService';
const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState('all');
  const [showMarkAllAsRead, setShowMarkAllAsRead] = useState(false);

  const _id = useSelector((state) => state.authReducer.user?._id);
  const { data:mainnotifications = [], refetch } = useGetNotificationsQuery(_id);



  useEffect(() => {
    if(notifications){

      setNotifications(mainnotifications)
    }
  }, [mainnotifications])
  
  const markAllAsRead = () => {
    setNotifications(notifications.map(n => ({ ...n, status: "read" })));
    setShowMarkAllAsRead(false);
    // API call to mark all notifications as read
  };

  const filteredNotifications = filter === 'all' ? notifications : notifications.filter(n => n.status == "unread");

  return (
    <div className="max-w-2xl mx-auto mt-4 bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-900">Notifications</h1>
          {showMarkAllAsRead && (
            <button
              onClick={markAllAsRead}
              className="text-blue-600 hover:text-blue-800 text-sm font-medium"
            >
              Mark all as read
            </button>
          )}
        </div>
        <div className="mt-2 flex space-x-2">
          <button
            onClick={() => setFilter('all')}
            className={`px-3 py-1 rounded-full text-sm font-medium ${
              filter === 'all' ? 'bg-blue-100 text-blue-800' : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            All
          </button>
          <button
            onClick={() => setFilter('unread')}
            className={`px-3 py-1 rounded-full text-sm font-medium ${
              filter === 'unread' ? 'bg-blue-100 text-blue-800' : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            Unread
          </button>
        </div>
      </div>
      <div className="divide-y divide-gray-100">
        {filteredNotifications.map((notification) => 
{
  if(notification.type == "info"){
    return (
<InfoNotification notification={notification}/>
);
   }else{
    return(

<ActionNotification notification={notification}/>
);
}


 } )}
      </div>
    </div>
  );
};

export default Notifications;