import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import currency  from "currency-formatter";
import { HiOutlineChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { usePlaceCashOnDeliveryOrderMutation, useSendPaymentMutation } from "../../store/services/paymentService";
import { useGetUserByIdQuery } from "../../store/services/authService";
import { useGetUserAddressesQuery } from "../../store/services/addressService";
import toast, { Toaster } from "react-hot-toast";
import { emptyCart } from "../../store/reducers/cartReducer";
import useRazorpay from "react-razorpay";
const Payment = () => {
  const navigate = useNavigate();
  const { cart, total,totalshipping } = useSelector((state) => state.cart);
  const [doPayment, response] = useSendPaymentMutation();
  const { userToken, user } = useSelector((state) => state.authReducer);
  const [paymentMethod, setPaymentMethod] = useState("online");

  const [userData, setUserData] = useState({
    name: '',
    email: ''
  });
  const [formAddressData, setFormAddressData] = useState({
    addressLine1: '',
    city: '',
    postalCode: '',
    state: '',
    country: 'India'
  });
console.log(user)
  const { data: userProfile, isSuccess } = useGetUserByIdQuery(user._id);
  const { data: userAddresses, isLoading: isAddressloading } = useGetUserAddressesQuery(user._id);
  const [checkoutLoading, setcheckoutLoading] = useState(false)
  const [placeCodOrder] = usePlaceCashOnDeliveryOrderMutation();


  useEffect(() => {
    if (isSuccess) {
      setUserData({
        name: userProfile.firstName,
        email: userProfile.email
      });
    }
  }, [isSuccess, userProfile]);

  useEffect(() => {
    if (userAddresses && userAddresses.length > 0) {
      const firstAddress = userAddresses[0];
      setFormAddressData({
        ...formAddressData,
        addressLine1: firstAddress.addressLine1,
        city: firstAddress.city,
        postalCode: firstAddress.postalCode,
        state: firstAddress.state,
        country: firstAddress.country
      });
    }
  }, [userAddresses]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'name' || name === 'email') {
      setUserData({
        ...userData,
        [name]: value
      });
    } else {
      setFormAddressData({
        ...formAddressData,
        [name]: value
      });
    }
  };
  const dispatch = useDispatch();



  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleCheckout = async () => {
    setcheckoutLoading(true)
    if (paymentMethod === "online") {
      doPayment({ cart, id: user._id });
      console.log("Processing online payment...");
    } else if (paymentMethod === "cashOnDelivery") {
      const orderData = {
        userId: user._id,
        address: formAddressData,
        cart: cart // Assuming you have cart data
      };
      try {
        const response = await placeCodOrder(orderData);
        toast.success(response.data.message);
        localStorage.removeItem("cart");
        dispatch(emptyCart());
        navigate("/user-order-details/"+response.data.lastOrderId);
        setcheckoutLoading(false)
      } catch (error) {
        // Handle error
      }
    }
  };


  useEffect(() => {
    if (response?.isSuccess) {
      handlePayment(response?.data?.id);
    }
  }, [response]);

  const [Razorpay, isLoaded] = useRazorpay();

  const handlePayment = useCallback(async (mainId) => {
    // const order = await createOrder(params);

    const options = {
      key: "rzp_test_H93fmAWVWOOKdH",
      amount: "3000",
      currency: "INR",
      name: "Petzoy Corp",
      description: "Test Transaction",
      image: "https://api.petzoy.in/mainlogo/logo.png",
      order_id: mainId,
      handler: (res) => {
        console.log(res);
      },
      prefill: {
        name: "Petzoy",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Petzoy Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, [Razorpay]);




  return (
    <>
    <div className="w-full flex flex-col gap-3 p-2 mb-5" style={{backgroundColor:"#f8f8f8"}} >
  
    <Toaster position="top-right" reverseOrder={true} />
    <p className="text-sm font-normal text-lightText capitalize flex items-center " style={{marginBottom: "0px"}}>

      <span className="px-1 ">
        <HiOutlineChevronRight />
      </span>
      <span className="capitalize fontstyleclass text-primeColor">
        Home / Order
      </span>
    </p>
  </div>
    <div className="max-w-container mx-auto px-4">
     


<section className="shop-checkout container">
     
<div className="row pb-10" >
  <div className="col-md-9">
  <div className="billing-info__wrapper">
            <h4>BILLING DETAILS</h4>
            <div className="row">

            <div className="row">
              <div className="col-md-6">
                <div className="form-floating my-3">
                <input type="text" className="form-control" name="name" value={userData.name} disabled placeholder="First Name"/>
                  <label for="checkout_first_name">First Name</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating my-3">
                <input type="text" className="form-control" name="email" value={userData.email} disabled placeholder="E-Mail"/>
                  <label for="checkout_last_name">E-Mail</label>
                </div>
              </div>
             
              <div className="col-md-12">
                <div className="search-field my-3">
                  <div className="form-label-fixed hover-container">
                    <label for="search-dropdown" className="form-label">Country /
                      Region*</label>
                    <div className="js-hover__open">
                      <input type="text"
                        className="form-control form-control-lg search-field_actor search-field_arrow-down"
                        id="search-dropdown" name="search-keyword" disabled readonly placeholder="India"/>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-floating mt-3 mb-3">
                <input type="text" className="form-control" name="addressLine1" value={formAddressData.addressLine1} onChange={handleChange} placeholder="Street Address *"/>
                  <label for="checkout_company_name">Street Address *</label>
                </div>
                
              </div>
              <div className="col-md-12">
                <div className="form-floating my-3">
                <input type="text" className="form-control" name="city" value={formAddressData.city} onChange={handleChange} placeholder="Town / City *"/>
                  <label for="checkout_city">Town / City *</label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-floating my-3">
                <input type="text" className="form-control" name="postalCode" value={formAddressData.postalCode} onChange={handleChange} placeholder="Postcode / ZIP *"/>
                  <label for="checkout_zipcode">Postal Code</label>
                </div>
              </div>
              
              
            
              <div className="col-md-12">
                <div className="form-floating my-3">
                <input type="text" className="form-control" name="state" value={formAddressData.state} onChange={handleChange} placeholder="State *"/>
                  <label for="checkout_email">State *</label>
                </div>
              </div>
              </div>









          

            </div>
            {/* <div className="col-md-12">
              <div className="mt-3">
                <textarea className="form-control form-control_gray" placeholder="Order Notes (optional)" cols="30"
                  rows="8"></textarea>
              </div>
            </div> */}
          </div>

  </div>
<div className="col-md-3">
<form name="checkout-form" action>
<div className="shopping-cart" >
          <div className="shopping-cart__totals-wrapper" >
          <div className="sticky-content">
            <div className="shopping-cart__totals" >
              <h3>My Order</h3>
              <table className="cart-totals">
                <tbody>
                  <tr>
                    <th>Subtotal</th>
                    <td style={{textAlign:"end"}} > {currency.format(
                            total,
                            {
                              code: "INR",
                            }
                          )}</td>
                  </tr>
                  <tr>
                    <th>Shipping Charges</th>
                    <td style={{textAlign:"end"}}>   {currency.format(
                            totalshipping,
                            {
                              code: "INR",
                            }
                          )}</td>
                  </tr>
                 
                  {/* <tr>
                    <th>GST</th>
                    <td style={{textAlign:"end"}}>₹ 19</td>
                  </tr> */}
                  <tr>
                    <th>Total</th>
                    <td style={{textAlign:"end"}}>  {currency.format(
                            total + totalshipping,
                            {
                              code: "INR",
                            }
                          )}</td>
                  </tr>
                  <tr>
                    <th>                 <input 
          type="radio" 
          className="form-check-input form-check-input_fill" 
          name="checkout_payment_method" 
          id="checkout_payment_method_online" 
          value="online" 
          checked={paymentMethod === "online"} 
          onChange={handlePaymentMethodChange} 
        />
</th>
                    <td style={{textAlign:"end"}}>Online Payment</td>
                  </tr>
                  <tr>
                    <th>                 <input 
          type="radio" 
          className="form-check-input form-check-input_fill" 
          name="checkout_payment_method" 
          id="checkout_payment_method_cashOnDelivery" 
          value="cashOnDelivery" 
          checked={paymentMethod === "cashOnDelivery"} 
          onChange={handlePaymentMethodChange} 
        /></th>
                    <td style={{textAlign:"end"}}>Cash On Delivery</td>
                  </tr>
                 
                </tbody>
              </table>

              <div className="policy-text">
                  Your personal data will be used to process your order,
                  support your experience throughout this website, and for
                  other purposes described in our <a href="terms.html" target="_blank">privacy policy</a>.
                </div>
               
            </div>
            
          </div>
        </div>
        </div>
        <div className="checkout-form">
          
          <div className="checkout__totals-wrapper">
             
             
             
              <div className="mobile_fixed-btn_wrapper">
            
                  <button onClick={handleCheckout} disabled={checkoutLoading} className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
                  {checkoutLoading ? 'Processing':'Place Order'}  
                  </button>
            
            
            </div>
          </div>
        </div>
      </form>
</div>

  
  

</div>
    </section>
    </div>
    </>
  );
};

export default Payment;
