import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const subOwnerService = createApi({
  reducerPath: "subOwners",
  tagTypes: ["SubOwners"],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.petzoy.in/api/', // Adjust the base URL if necessary
    prepareHeaders: (headers, { getState }) => {
      const reducers = getState();
      const token = reducers?.authReducer?.userToken;
      headers.set("authorization", token ? `Bearer ${token}` : "");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addPetOwnerRequest: builder.mutation({
      query: (body) => ({
        url: '/sub-owner-requests/add-pet-owner-request',
        method: "POST",
        body,
      }),
      invalidatesTags: ["SubOwners"],
    }),
    checkPetSubOwner: builder.query({
      query: ({ petId, userId }) => ({
        url: `/sub-owner-requests/check-sub-owner/${petId}/${userId}`,
        method: "GET",
      }),
      providesTags: ["SubOwners"],
    }),
    removePetSubOwner: builder.mutation({
      query: (body) => ({
        url: '/sub-owner-requests/remove-sub-owner',
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["SubOwners"],
    }),
    acceptPetSubOwner: builder.mutation({
      query: (body) => ({
        url: '/sub-owner-requests/accept-sub-owner',
        method: "POST",
        body,
      }),
      invalidatesTags: ["SubOwners"],
    }),
    declinePetSubOwner: builder.mutation({
      query: (body) => ({
        url: '/sub-owner-requests/decline-sub-owner',
        method: "POST",
        body,
      }),
      invalidatesTags: ["SubOwners"],
    }),
    getPendingSubOwnerRequests: builder.query({
      query: (petId) => ({
        url: `/sub-owner-requests/pending-sub-owner-requests/${petId}`,
        method: "GET",
      }),
      providesTags: ["SubOwners"],
    }),
    getPetsWithSubOwner: builder.query({
      query: ({ myUserId, otherUserId }) => ({
        url: `/sub-owner-requests/pets-with-sub-owner/${myUserId}/${otherUserId}`,
        method: "GET",
      }),
      providesTags: ["SubOwners"],
    }),
    hasSentSubOwnerRequest: builder.query({
        query: ({ petId, senderId }) => ({
          url: `/sub-owner-requests/has-sent-sub-owner-request/${petId}/${senderId}`,
          method: "GET",
        }),
        providesTags: ["SubOwners"],
      }),
      deletePetOwnerRequest: builder.mutation({
        query: ({ requestId,petId }) => ({
            url: '/sub-owner-requests/delete-pet-owner-request', // Adjust to your route
            method: 'DELETE',
            body: { requestId,petId },
        }),
        invalidatesTags: ['SubOwners'], // Invalidate relevant tags if needed
    }),
  }),
});

export const {
  useAddPetOwnerRequestMutation,
  useCheckPetSubOwnerQuery,
  useRemovePetSubOwnerMutation,
  useAcceptPetSubOwnerMutation,
  useDeclinePetSubOwnerMutation,
  useGetPendingSubOwnerRequestsQuery,
  useGetPetsWithSubOwnerQuery,
  useHasSentSubOwnerRequestQuery,
  useDeletePetOwnerRequestMutation
} = subOwnerService;

export default subOwnerService;
