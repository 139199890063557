import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  useCatProductsQuery,
  useGetSubcategoryProductsQuery,
} from "../../store/services/homeProducts";
import { discount } from "../../utils/discount";
import currency from "currency-formatter";
import { useAllCategoriesQuery } from "../../store/services/categoryService";
import HomeProducts from "../../components/HomeProducts/HomeProducts";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useGetSubcategoriesByCategoryQuery } from "../../store/services/subcategoryService";
import MainContent from "../../components/Shop/MainContent";
import Layout from "../../components/Shop/Layout";
import Header from "../../components/Shop/Header";
import Sidebar from "./Components/Sidebar";
import Feed from "./Components/Feed";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import Joyride, { STATUS } from "react-joyride";
import { setDemoShow } from "../../store/reducers/authReducer";
import { useGetOverlayStepsByPageQuery } from "../../store/services/overlayService";

const ShoppingPage = () => {
  const dispatch = useDispatch();
  const [maincat, setmaincat] = useState({});
  const [mainsubcat, setmainsubcat] = useState({});
  const showdemo = useSelector((state) => state.authReducer.demoshowhome);
  const { data: catdata, isFetching: catfetch } = useAllCategoriesQuery();
  const { data, isFetching } = useGetSubcategoryProductsQuery({
    subcategoryId: mainsubcat?._id,
    page: "",
    skip: !mainsubcat?._id,
  });

  const {
    data: subcategories,
    error,
    isLoading,
  } = useGetSubcategoriesByCategoryQuery(maincat._id);

  useEffect(() => {
    if (catdata) {
      setmaincat(catdata?.categories[0]);
    }
  }, [catdata]);

  useEffect(() => {
    setmainsubcat(subcategories?.subcategories[0]);
  }, [subcategories]);

  let i = 1;

  const ScrollerItem = ({ item }) => (
    <div
      onClick={() => setmaincat(item)}
      className={`flex-shrink-0 border rounded-lg shadow-md p-2 m-2 flex items-center cursor-pointer transition-all duration-200 ease-in-out ${
        maincat === item
          ? "bg-gradient-to-r from-purple-500 to-purple-700 text-white border-transparent"
          : "bg-white text-black border-gray-300 hover:border-purple-500"
      }`}
    >
      <img
        src={`https://api.petzoy.in/assets/${item.image}`}
        alt={item.name}
        className="w-8 h-8 mr-2 rounded-full object-cover"
      />
      <div
        className={`font-medium text-lg ${
          maincat === item ? "font-bold" : "font-normal"
        }`}
      >
        {item.name}
      </div>
    </div>
  );

  const HorizontalScroller = ({ items }) => (
    <div className="flex md:justify-center  lg:justify-center  overflow-x-auto p-2 scrollbar-hidden bg-gray-200 ">
      {items.map((item, index) => (
        <ScrollerItem key={index} item={item} />
      ))}
    </div>
  );
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const { data:story=[],  } = useGetOverlayStepsByPageQuery('shop');
  

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
  
    if (finishedStatuses.includes(status)) {
      dispatch(setDemoShow(true))
    }
  
  };
  


  return (
  
    <div className="flex flex-col min-h-screen w-full mx-auto bg-white">
        {!showdemo && <Joyride
      callback={handleJoyrideCallback}
      hideCloseButton
      steps={story.map((step, index) =>
        index === 0 ? { ...step, disableBeacon: true } : step
      )} 
          showProgress
        showSkipButton
        scrollToFirstStep
          continuous
        />}
      <div className="relative">
        <div className="overflow-x-auto overflow-hidden scrollbar-hidden mainshop ">
          <HorizontalScroller items={catdata?.categories || []} />
        </div>
      </div>
      <main className="flex flex-col xl:flex-row max-w-7xl mx-auto w-full">
        {/* Sidebar */}
        <Sidebar
          maindata={subcategories?.subcategories}
          // handlePetTypeSelect={handlePetTypeSelect}
          // handleTagSelect={handleTagSelect}
          onpress={(item) => setmainsubcat(item)}
          mainsubcat={mainsubcat}
          className="xl:w-1/4 xl:flex-shrink-0"
        />
        {/* Feed */}
        <Feed
          // maindata={filteredPosts}
          maincat={maincat}
          maindata={data?.products || []}
          className="xl:w-2/4 flex-grow "
        />
      </main>
    </div>
  );
};

export default ShoppingPage;
