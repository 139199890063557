import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Modal,
  TextField,
  Typography,
  useTheme,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  useMediaQuery,
  MenuItem,
  FormControlLabel,
  Checkbox,
  styled,
  Badge,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPetsType } from "../../store/reducers/authReducer";
import { Android12Switch } from "../../components/AndroidSwitch";
import UserImage from "../../components/UserImage";
import FlexBetween from "../../components/FlexBetween";
import { useCreateNewPetUserMutation } from "../../store/services/allpetService";
import toast from "react-hot-toast";

const CreatePetModal = ({ open, setOpen,serviceId }) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();

  const [formdata, setformdata] = useState({
    petType: "",
    petName: "",
    petBreed: "",
    petAge: `2024-01-01`,
    petWeight: "",
    gender: "",
    private: false,
  });
  const [selected_type, setselected_type] = useState(null);
  const [preview, setPreview] = useState(null);
  const [imgfile, setimgfile] = useState(null);

  const [current_addstep, setcurrent_addstep] = useState(0);
  const { palette } = useTheme();

  const token = useSelector((state) => state.authReducer.token);
  const petstype = useSelector((state) => state.authReducer.petstype);
  const loggedInUserId = useSelector((state) => state.authReducer.user._id);
  const dispatch = useDispatch();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "2px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  const getpet_type = async () => {
    const response = await fetch(`https://api.petzoy.in/pets/pet_type/all`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setPetsType({ petstype: data }));
  };

  useEffect(() => {
    getpet_type();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setPreview(null);
    setformdata({
      petType: "",
      petName: "",
      petBreed: "",
      petAge: "",
      petWeight: "",
    });
    setOpen(false);
  };

  const [all_services, setall_services] = useState([]);

  const [selectedServices, setSelectedServices] = useState([]);

  const getallservices = async () => {
    const response = await fetch(
      `https://api.petzoy.in/api/services/services`,
      {
        method: "GET",
        // headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    const petServices = data.filter((service) => service.type == "pet");
    setall_services(petServices);
    // setall_services(data)
  };

  useEffect(() => {
    getallservices();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setimgfile(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleFormInput = (event) => {
    console.log(event);
    const { name, value } = event.target;
    setformdata({
      ...formdata,
      [name]: value,
    });
  };

  console.log("select type ", selected_type);

  const [createPetMutation] = useCreateNewPetUserMutation();
  const handleSave = async () => {

    if (current_addstep != 3) {
      setcurrent_addstep(current_addstep + 1);
      return;
    }

    if(!formdata.petName){
      toast.error("Pet Name Required")
      return
    }
    if(!formdata.petAge){
      toast.error("Pet Age Required")
      return
    }
    

    const formData = new FormData();
    formData.append("name", formdata.petName);
    formData.append("breed", formdata.petBreed);
    formData.append("age", formdata.petAge);
    formData.append("type", selected_type.petType);
    formData.append("gender", formdata.gender);
    formData.append("weight", formdata.petWeight);
    formData.append("private", formdata.private);

    formData.append("services", JSON.stringify(selectedServices));

    if (imgfile) {
      formData.append("picture", imgfile);
      formData.append("picturePath", imgfile.name);
    }

    const mutationResult = await createPetMutation({
      userId: loggedInUserId,
      token: token,
      formData: formData,
    });

    console.log("Mutation result:", mutationResult);
    setimgfile(null);
    setimgfile("");
    // Here you can dispatch an action to save the pet details
    // For example: dispatch(createPet({ name: petName, breed: petBreed, age: petAge, weight: petWeight, image: preview }));
    handleClose();
  };

  const handleServiceSelection = (service) => {
    const index = selectedServices.findIndex((s) => s._id === service._id);
    if (index === -1) {
      setSelectedServices([...selectedServices, service]);
    } else {
      const updatedServices = [...selectedServices];
      updatedServices.splice(index, 1);
      setSelectedServices(updatedServices);
    }
  };



  useEffect(() => {
    
    // Preselect service if serviceId is passed
    if (serviceId && all_services) {
      const preselectedService = all_services.find(service => service._id === serviceId);
      if (preselectedService) {
        setSelectedServices([preselectedService]);  // Preselect the service
      }
    }
  }, [serviceId, all_services]);



  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        bgcolor: "rgba(0,0,0,0)",
        border: "2px solid #000",
        flexDirection: "column",
        boxShadow: 24,
      }}
    >
      <>
        {" "}
        {!isNonMobileScreens && (
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
              bgcolor: palette.primary.main,
              display: "flex",
              boxShadow: 24,
              height: "70px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            <i onClick={handleClose} className="fa-solid fa-chevron-left"></i>
            <Typography variant="h4" color={"black"}>
              Create Pet Profile
            </Typography>
            <i className="fa-solid fa-paw"></i>
          </Box>
        )}
        <Box
          sx={{
            width: isNonMobileScreens ? "30%" : "100%",
            maxWidth: "800px",
            bgcolor: "white",
            padding: 5,
            borderRadius: isNonMobileScreens ? 5 : 0,
            boxShadow: 24,
            height: isNonMobileScreens ? "auto" : "100%",
            overflowY: "scroll",
          }}
        >
          <Typography id="modal-modal-title" variant="h3" component="h3">
            {current_addstep == 0
              ? "Add Pet"
              : current_addstep == 1
              ? "What Type of Pet is it ?"
              : current_addstep == 2
              ? "Select Your Pet Breed"
              : current_addstep == 3
              ? "Set Your Pet Info"
              : "My New Member is..."}
          </Typography>

          {current_addstep == 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {petstype?.map((pet, index) => (
                <Box
                  key={index}
                  sx={{ width: "calc(50% - 20px)", margin: "10px 10px" }}
                >
                  <Button
                    onClick={() => {
                      setselected_type(pet);
                    }}
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ textAlign: "center", marginBottom: "10px" }}>
                      {selected_type == pet ? (
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            sx={{
                              width: isNonMobileScreens ? 150 : 100,
                              height: isNonMobileScreens ? 150 : 100,
                            }}
                            alt="Natacha"
                            src={`https://api.petzoy.in/assets/${pet?.picturePath}`}
                          />
                        </StyledBadge>
                      ) : (
                        <Avatar
                          sx={{
                            width: isNonMobileScreens ? 150 : 100,
                            height: isNonMobileScreens ? 150 : 100,
                          }}
                          alt="Natacha"
                          src={`https://api.petzoy.in/assets/${pet?.picturePath}`}
                        />
                      )}
                    </Box>
                    <Typography
                      variant="h4"
                      color={"black"}
                      sx={{ textAlign: "center" }}
                    >
                      {pet.petType}
                    </Typography>
                  </Button>
                </Box>
              ))}
            </Box>
          )}

          {current_addstep == 0 && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "250px",
                  alignSelf: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: 1,
                  borderRadius: "10px",
                  borderStyle: "dashed",
                }}
              >
                {!preview && <input type="file" onChange={handleImageUpload} />}
                {preview && (
                  <img
                    src={preview}
                    alt="pet"
                    style={{
                      width: "100%",
                      height: "250px",
                      alignSelf: "center",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </Box>

              <Typography>Upload Image</Typography>
              <Box></Box>
            </Box>
          )}

          {current_addstep == 2 && (
            <>
              <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
              {selected_type?.petBreeds?.length > 0 &&
                selected_type.petBreeds.map((petbreed) => (
                  <Chip
                    sx={{ margin: "4px" }}
                    avatar={
                      <Avatar

                      sx={{ bgcolor: 'white' }}
                        src={`https://api.petzoy.in/assets/${selected_type?.image}`}
                      />
                    }
                    label={petbreed.breedName}
                    variant={
                      petbreed.breedName == formdata.petBreed
                        ? "elevated"
                        : "outlined"
                    }
                    clickable
                    onClick={() => {
                      handleFormInput({
                        target: { name: "petBreed", value: petbreed.breedName },
                      });
                    }}
                  />
                ))}
              <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
            </>
          )}

          {current_addstep == 3 && (
            <>
              <TextField
                label="Pet Name"
                value={formdata.petName}
                type="text"
                name="petName"
                required
                onChange={handleFormInput}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Age (In Yrs.)"
                value={formdata.petAge}
                type="date"
                required
                name="petAge"
                onChange={handleFormInput}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Weight (In Kgs)"
                value={formdata.petWeight}
                type="number"
                name="petWeight"
                onChange={handleFormInput}
                fullWidth
                margin="normal"
              />

              <TextField
                select
                label="Pet Gender"
                value={formdata.gender}
                onChange={handleFormInput}
                name="gender"
                fullWidth
                margin="normal"
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </TextField>

              <FlexBetween>
                <FlexBetween gap="1rem" margin={"10px"}>
                  <img
                    style={{
                      objectFit: "cover",
                      width: "30px",
                      height: "30px",
                    }}
                    alt="user"
                    src={`https://www.freeiconspng.com/thumbs/lock-icon/lock-icon-11.png`}
                  />
                  <Box>
                    <Typography
                      color={"blac"}
                      variant="h5"
                      fontWeight="500"
                      sx={{
                        "&:hover": {
                          color: palette.primary.light,
                          cursor: "pointer",
                        },
                      }}
                    >
                      Private Account
                    </Typography>
                    <Typography color={"#000"} fontSize="0.75rem">
                      Keep my profile private
                    </Typography>
                  </Box>
                </FlexBetween>
                <FormControlLabel
                  control={
                    <Android12Switch
                      onClick={() =>
                        setformdata({ ...formdata, private: !formdata.private })
                      }
                      checked={formdata.private}
                    />
                  }
                  // label="Android 12"
                />
              </FlexBetween>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "300px",
                  // flexDirection: "row",
                  // justifyContent: "space-evenly",
                  alignItems: "center",
                }}
                gridColumn="span 4"
              >
                {all_services?.map((service) => (
                  <FlexBetween sx={{ width: "100%" }}>
                    <FlexBetween gap="1rem">
                      <UserImage image={service.image} size="55px" />
                      <Box>
                        <Typography
                          color={"black"}
                          variant="h5"
                          fontWeight="500"
                          sx={{
                            "&:hover": {
                              color: palette.primary.light,
                              cursor: "pointer",
                            },
                          }}
                        >
                          {service.name}
                        </Typography>
                        <Typography color={"gray"} fontSize="0.75rem">
                          {service.description}
                        </Typography>
                      </Box>
                    </FlexBetween>
                    <FormControlLabel
                      control={
                        <Android12Switch
                          onClick={() => handleServiceSelection(service)}
                          checked={selectedServices.find(
                            (s) => s._id === service._id
                          )}
                        />
                      }
                      // label="Android 12"
                    />
                  </FlexBetween>
                ))}
              </Box>
            </>
          )}
          <Box
            sx={[
              { display: "flex", justifyContent: "space-between" },
              !isNonMobileScreens && {
                position: "absolute",
                bottom: "20px",
                width: "80%",
              },
            ]}
          >
            <Button
              variant="outlined"
              size="large"
              onClick={() => {
                if (current_addstep == 0) {
                  handleClose();
                } else {
                  setcurrent_addstep(current_addstep - 1);
                }
              }}
            >
              {current_addstep != 0 ? "Back" : "Cancel"}
            </Button>
            <Button variant="contained" size="large" onClick={handleSave}>
              {current_addstep != 3 ? "Next" : "Save"}
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default CreatePetModal;
